// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var CustomerVesselList = require("./CustomerVesselList");
var CustomerVesselListEdit = require("./CustomerVesselListEdit");

function str(prim) {
  return prim;
}

var component = ReasonReact.reducerComponent("CustomerListItem");

function make(onEdit, store, customer, _children) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function (self) {
              var match = self[/* state */1];
              var classes;
              switch (match) {
                case 0 : 
                    classes = "li-container";
                    break;
                case 1 : 
                    classes = "li-container selected";
                    break;
                case 2 : 
                    classes = "li-container selected edit";
                    break;
                
              }
              var match$1 = self[/* state */1];
              var tmp;
              switch (match$1) {
                case 0 : 
                    tmp = React.createElement("div", undefined);
                    break;
                case 1 : 
                    tmp = React.createElement("div", undefined, React.createElement("div", {
                              className: "customer-detail"
                            }, "Contact email: " + customer[/* contact_email */1]), ReasonReact.element(undefined, undefined, CustomerVesselList.make(customer, /* array */[])), React.createElement("button", {
                              className: "primary",
                              onClick: (function (_e) {
                                  return Curry._1(self[/* send */3], /* To */[/* Edit */2]);
                                })
                            }, "EDIT"));
                    break;
                case 2 : 
                    tmp = React.createElement("div", undefined, ReasonReact.element(undefined, undefined, CustomerVesselListEdit.make(onEdit, store, customer, /* array */[])));
                    break;
                
              }
              return React.createElement("div", {
                          className: classes
                        }, React.createElement("li", {
                              onClick: (function (param) {
                                  var match = self[/* state */1];
                                  if (match !== 0) {
                                    return Curry._1(self[/* send */3], /* To */[/* Hidden */0]);
                                  } else {
                                    return Curry._1(self[/* send */3], /* To */[/* Show */1]);
                                  }
                                })
                            }, customer[/* name */3]), tmp);
            }),
          /* initialState */(function (param) {
              return /* Hidden */0;
            }),
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */(function (action, state) {
              return /* Update */Block.__(0, [action[0]]);
            }),
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

exports.str = str;
exports.component = component;
exports.make = make;
/* component Not a pure module */

// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var KnlPng = require("../knl.png");
var UserWidget = require("../Components/UserWidget");
var ReasonReact = require("reason-react/src/ReasonReact.js");

var logo = KnlPng;

var component = ReasonReact.statelessComponent("Header");

function str(prim) {
  return prim;
}

function make(store, $staropt$star, _children) {
  var login = $staropt$star !== undefined ? $staropt$star : false;
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function (_self) {
              return React.createElement("div", {
                          className: "col-sm-12 col-md-12 col-lg-11"
                        }, React.createElement("header", {
                              className: "row"
                            }, login ? React.createElement("label", {
                                    className: "col-sm-4 hide-when-not-mobile"
                                  }) : React.createElement("label", {
                                    className: "col-sm-4 button drawer-toggle",
                                    htmlFor: "doc-drawer-checkbox"
                                  }), React.createElement("span", {
                                  className: "col-sm-4 col-lg-2 col-md-4 header-logo"
                                }, React.createElement("img", {
                                      src: logo
                                    })), React.createElement("div", {
                                  className: "col-sm-4 col-lg-10 col-md-7"
                                }, React.createElement("div", {
                                      className: "user-widget-container"
                                    }, login ? React.createElement("div", undefined) : ReasonReact.element(undefined, undefined, UserWidget.make(store, /* array */[]))))));
            }),
          /* initialState */component[/* initialState */10],
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */component[/* reducer */12],
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

exports.logo = logo;
exports.component = component;
exports.str = str;
exports.make = make;
/* logo Not a pure module */

// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Dialog = require("./Dialog");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var UserData = require("../Util/UserData");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var Caml_builtin_exceptions = require("bs-platform/lib/js/caml_builtin_exceptions.js");

function str(prim) {
  return prim;
}

var component = ReasonReact.reducerComponent("CustomerVesselListEdit");

function make(onEdit, store, customer, _children) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */(function (self) {
              return Curry._1(self[/* send */3], /* Radios */Block.__(8, [/* Load */0]));
            }),
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function (self) {
              var match = self[/* state */1][/* radios */6];
              var tmp;
              tmp = typeof match === "number" || match.tag !== 1 ? React.createElement("tr", undefined) : $$Array.of_list(List.map((function (x) {
                            return React.createElement("tr", {
                                        key: x[/* uuid */3],
                                        value: x[/* uuid */3]
                                      }, React.createElement("td", {
                                            style: {
                                              marginLeft: "10px"
                                            }
                                          }, React.createElement("input", {
                                                checked: List.mem(x, self[/* state */1][/* selectedRadios */5]),
                                                type: "checkbox",
                                                onChange: (function (e) {
                                                    return Curry._1(self[/* send */3], e.target.checked ? /* SelectRadio */Block.__(7, [x]) : /* UnSelectRadio */Block.__(6, [x]));
                                                  })
                                              })), React.createElement("td", undefined, x[/* name */5]), React.createElement("td", {
                                            style: {
                                              marginRight: "25px",
                                              textAlign: "right"
                                            }
                                          }, "#" + UserData.getPrintableSid(x)));
                          }), List.filter((function (x) {
                                  if (self[/* state */1][/* radiosFilter */0] === "") {
                                    return true;
                                  } else {
                                    return x[/* name */5].toLowerCase().indexOf(self[/* state */1][/* radiosFilter */0].toLowerCase()) >= 0;
                                  }
                                }))(List.filter((function (x) {
                                      console.log(x);
                                      return !List.mem(x, customer[/* vessels */4]);
                                    }))(match[0]))));
              return React.createElement("div", undefined, self[/* state */1][/* promptEditVisible */1] ? ReasonReact.element(undefined, undefined, Dialog.make(300, 150, (function (param) {
                                      Curry._1(self[/* send */3], /* PromptEdit */Block.__(1, [false]));
                                      return Curry._1(self[/* send */3], /* Edit */Block.__(9, [/* Load */0]));
                                    }), (function (param) {
                                      return Curry._1(self[/* send */3], /* PromptEdit */Block.__(1, [false]));
                                    }), /* array */[React.createElement("div", {
                                          className: "user-page-dialog"
                                        }, React.createElement("p", undefined, React.createElement("strong", undefined, "Are you sure you want to edit this customer?")))])) : React.createElement("div", undefined), self[/* state */1][/* promptVesselsVisible */2] ? ReasonReact.element(undefined, undefined, Dialog.make(300, 200, (function (param) {
                                      Curry._1(self[/* send */3], /* PromptVessels */Block.__(2, [false]));
                                      return Curry._1(self[/* send */3], /* AddVessels */Block.__(10, [/* Load */0]));
                                    }), (function (param) {
                                      return Curry._1(self[/* send */3], /* PromptVessels */Block.__(2, [false]));
                                    }), /* array */[React.createElement("div", {
                                          className: "user-page-dialog"
                                        }, React.createElement("p", undefined, React.createElement("strong", undefined, "Are you sure you want to add the selected vessels to the customer?")))])) : React.createElement("div", undefined), React.createElement("form", {
                              className: "editable-form",
                              onSubmit: (function (prim) {
                                  prim.preventDefault();
                                  return /* () */0;
                                })
                            }, React.createElement("fieldset", {
                                  className: "bordered"
                                }, React.createElement("div", {
                                      className: "row responsive-label"
                                    }, React.createElement("div", {
                                          className: "col-sm-12 col-md-3"
                                        }, React.createElement("label", {
                                              className: "doc",
                                              htmlFor: "sf1-text"
                                            }, "Name")), React.createElement("div", {
                                          className: "col-sm-12 col-md-3"
                                        }, React.createElement("input", {
                                              className: "doc",
                                              id: "sf1-text",
                                              autoComplete: "off",
                                              placeholder: "Customer name",
                                              type: "text",
                                              value: self[/* state */1][/* name */4],
                                              onChange: (function (e) {
                                                  return Curry._1(self[/* send */3], /* UpdateName */Block.__(3, [e.target.value]));
                                                })
                                            }))), React.createElement("div", {
                                      className: "row responsive-label"
                                    }, React.createElement("div", {
                                          className: "col-sm-12 col-md-3"
                                        }, React.createElement("label", {
                                              className: "doc",
                                              htmlFor: "sf2-text"
                                            }, "Email")), React.createElement("div", {
                                          className: "col-sm-12 col-md-3"
                                        }, React.createElement("input", {
                                              className: "doc",
                                              id: "sf2-text",
                                              autoComplete: "off",
                                              placeholder: "Customer admin email",
                                              type: "text",
                                              value: self[/* state */1][/* email */3],
                                              onChange: (function (e) {
                                                  return Curry._1(self[/* send */3], /* UpdateEmail */Block.__(4, [e.target.value]));
                                                })
                                            }))), React.createElement("button", {
                                      onClick: (function (param) {
                                          return Curry._1(self[/* send */3], /* PromptEdit */Block.__(1, [true]));
                                        })
                                    }, "SAVE"))), List.length(customer[/* vessels */4]) > 0 ? React.createElement("form", {
                                className: "editable-form",
                                onSubmit: (function (prim) {
                                    prim.preventDefault();
                                    return /* () */0;
                                  })
                              }, React.createElement("fieldset", {
                                    className: "bordered"
                                  }, React.createElement("div", {
                                        className: "vessel-list-row row"
                                      }, React.createElement("div", {
                                            className: "vessel-list-column col-sm-12 col-md-12"
                                          }, React.createElement("fieldset", undefined, React.createElement("div", {
                                                    className: "row responsive-label"
                                                  }, React.createElement("div", {
                                                        className: "col-sm-12 col-md-12"
                                                      }, React.createElement("label", undefined, "Vessels and radios")))), React.createElement("table", {
                                                className: "knl-striped"
                                              }, React.createElement("tbody", undefined, $$Array.of_list(List.map((function (x) {
                                                              return React.createElement("tr", {
                                                                          key: x[/* uuid */3]
                                                                        }, React.createElement("td", {
                                                                              style: {
                                                                                marginLeft: "25px"
                                                                              }
                                                                            }, x[/* name */5]), React.createElement("td", {
                                                                              style: {
                                                                                marginRight: "25px",
                                                                                textAlign: "right"
                                                                              }
                                                                            }, "#" + UserData.getPrintableSid(x)));
                                                            }), customer[/* vessels */4])))))))) : React.createElement("div", undefined), React.createElement("form", {
                              className: "editable-form",
                              onSubmit: (function (prim) {
                                  prim.preventDefault();
                                  return /* () */0;
                                })
                            }, React.createElement("fieldset", {
                                  className: "bordered"
                                }, React.createElement("div", {
                                      className: "vessel-list-row row"
                                    }, React.createElement("div", {
                                          className: "vessel-list-column col-sm-12 col-md-12"
                                        }, React.createElement("fieldset", undefined, React.createElement("div", {
                                                  className: "row responsive-label"
                                                }, React.createElement("div", {
                                                      className: "col-sm-12 col-md-12"
                                                    }, React.createElement("label", {
                                                          className: "doc",
                                                          htmlFor: "sf2-text"
                                                        }, "Add vessels to customer")), React.createElement("div", {
                                                      className: "col-sm-12 col-md"
                                                    }, React.createElement("input", {
                                                          className: "doc",
                                                          id: "sf2-text",
                                                          autoComplete: "off",
                                                          placeholder: "Filter the list below",
                                                          type: "text",
                                                          value: self[/* state */1][/* radiosFilter */0],
                                                          onChange: (function (e) {
                                                              return Curry._1(self[/* send */3], /* UpdateRadiosFilter */Block.__(0, [e.target.value]));
                                                            })
                                                        })))), React.createElement("table", {
                                              className: "knl-striped"
                                            }, React.createElement("tbody", undefined, tmp)))), React.createElement("button", {
                                      disabled: List.length(self[/* state */1][/* selectedRadios */5]) === 0,
                                      onClick: (function (param) {
                                          return Curry._1(self[/* send */3], /* PromptVessels */Block.__(2, [true]));
                                        })
                                    }, "SAVE"))));
            }),
          /* initialState */(function (param) {
              return /* record */[
                      /* radiosFilter */"",
                      /* promptEditVisible */false,
                      /* promptVesselsVisible */false,
                      /* email */customer[/* contact_email */1],
                      /* name */customer[/* name */3],
                      /* selectedRadios : [] */0,
                      /* radios : Idle */0,
                      /* edit : Idle */0,
                      /* addVessels : Idle */0
                    ];
            }),
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */(function (action, state) {
              switch (action.tag | 0) {
                case 0 : 
                    return /* Update */Block.__(0, [/* record */[
                                /* radiosFilter */action[0],
                                /* promptEditVisible */state[/* promptEditVisible */1],
                                /* promptVesselsVisible */state[/* promptVesselsVisible */2],
                                /* email */state[/* email */3],
                                /* name */state[/* name */4],
                                /* selectedRadios */state[/* selectedRadios */5],
                                /* radios */state[/* radios */6],
                                /* edit */state[/* edit */7],
                                /* addVessels */state[/* addVessels */8]
                              ]]);
                case 1 : 
                    return /* Update */Block.__(0, [/* record */[
                                /* radiosFilter */state[/* radiosFilter */0],
                                /* promptEditVisible */action[0],
                                /* promptVesselsVisible */state[/* promptVesselsVisible */2],
                                /* email */state[/* email */3],
                                /* name */state[/* name */4],
                                /* selectedRadios */state[/* selectedRadios */5],
                                /* radios */state[/* radios */6],
                                /* edit */state[/* edit */7],
                                /* addVessels */state[/* addVessels */8]
                              ]]);
                case 2 : 
                    return /* Update */Block.__(0, [/* record */[
                                /* radiosFilter */state[/* radiosFilter */0],
                                /* promptEditVisible */state[/* promptEditVisible */1],
                                /* promptVesselsVisible */action[0],
                                /* email */state[/* email */3],
                                /* name */state[/* name */4],
                                /* selectedRadios */state[/* selectedRadios */5],
                                /* radios */state[/* radios */6],
                                /* edit */state[/* edit */7],
                                /* addVessels */state[/* addVessels */8]
                              ]]);
                case 3 : 
                    return /* Update */Block.__(0, [/* record */[
                                /* radiosFilter */state[/* radiosFilter */0],
                                /* promptEditVisible */state[/* promptEditVisible */1],
                                /* promptVesselsVisible */state[/* promptVesselsVisible */2],
                                /* email */state[/* email */3],
                                /* name */action[0],
                                /* selectedRadios */state[/* selectedRadios */5],
                                /* radios */state[/* radios */6],
                                /* edit */state[/* edit */7],
                                /* addVessels */state[/* addVessels */8]
                              ]]);
                case 4 : 
                    return /* Update */Block.__(0, [/* record */[
                                /* radiosFilter */state[/* radiosFilter */0],
                                /* promptEditVisible */state[/* promptEditVisible */1],
                                /* promptVesselsVisible */state[/* promptVesselsVisible */2],
                                /* email */action[0],
                                /* name */state[/* name */4],
                                /* selectedRadios */state[/* selectedRadios */5],
                                /* radios */state[/* radios */6],
                                /* edit */state[/* edit */7],
                                /* addVessels */state[/* addVessels */8]
                              ]]);
                case 5 : 
                    throw [
                          Caml_builtin_exceptions.match_failure,
                          /* tuple */[
                            "CustomerVesselListEdit.re",
                            54,
                            32
                          ]
                        ];
                case 6 : 
                    var radio = action[0];
                    return /* Update */Block.__(0, [/* record */[
                                /* radiosFilter */state[/* radiosFilter */0],
                                /* promptEditVisible */state[/* promptEditVisible */1],
                                /* promptVesselsVisible */state[/* promptVesselsVisible */2],
                                /* email */state[/* email */3],
                                /* name */state[/* name */4],
                                /* selectedRadios */List.filter((function (x) {
                                          return Caml_obj.caml_notequal(x, radio);
                                        }))(state[/* selectedRadios */5]),
                                /* radios */state[/* radios */6],
                                /* edit */state[/* edit */7],
                                /* addVessels */state[/* addVessels */8]
                              ]]);
                case 7 : 
                    return /* Update */Block.__(0, [/* record */[
                                /* radiosFilter */state[/* radiosFilter */0],
                                /* promptEditVisible */state[/* promptEditVisible */1],
                                /* promptVesselsVisible */state[/* promptVesselsVisible */2],
                                /* email */state[/* email */3],
                                /* name */state[/* name */4],
                                /* selectedRadios : :: */[
                                  action[0],
                                  state[/* selectedRadios */5]
                                ],
                                /* radios */state[/* radios */6],
                                /* edit */state[/* edit */7],
                                /* addVessels */state[/* addVessels */8]
                              ]]);
                case 8 : 
                    var match = action[0];
                    if (typeof match === "number") {
                      return /* UpdateWithSideEffects */Block.__(2, [
                                /* record */[
                                  /* radiosFilter */state[/* radiosFilter */0],
                                  /* promptEditVisible */state[/* promptEditVisible */1],
                                  /* promptVesselsVisible */state[/* promptVesselsVisible */2],
                                  /* email */state[/* email */3],
                                  /* name */state[/* name */4],
                                  /* selectedRadios */state[/* selectedRadios */5],
                                  /* radios : Loading */1,
                                  /* edit */state[/* edit */7],
                                  /* addVessels */state[/* addVessels */8]
                                ],
                                (function (self) {
                                    UserData.getVessels(/* () */0).then((function (result) {
                                            return Promise.resolve(Curry._1(self[/* send */3], /* Radios */Block.__(8, [result])));
                                          }));
                                    return /* () */0;
                                  })
                              ]);
                    } else if (match.tag) {
                      var match$1 = match[0];
                      if (match$1) {
                        return /* Update */Block.__(0, [/* record */[
                                    /* radiosFilter */state[/* radiosFilter */0],
                                    /* promptEditVisible */state[/* promptEditVisible */1],
                                    /* promptVesselsVisible */state[/* promptVesselsVisible */2],
                                    /* email */state[/* email */3],
                                    /* name */state[/* name */4],
                                    /* selectedRadios */state[/* selectedRadios */5],
                                    /* radios : Failed */Block.__(0, [match$1[0]]),
                                    /* edit */state[/* edit */7],
                                    /* addVessels */state[/* addVessels */8]
                                  ]]);
                      } else {
                        return /* UpdateWithSideEffects */Block.__(2, [
                                  /* record */[
                                    /* radiosFilter */state[/* radiosFilter */0],
                                    /* promptEditVisible */state[/* promptEditVisible */1],
                                    /* promptVesselsVisible */state[/* promptVesselsVisible */2],
                                    /* email */state[/* email */3],
                                    /* name */state[/* name */4],
                                    /* selectedRadios */state[/* selectedRadios */5],
                                    /* radios : Failed */Block.__(0, ["Unauthorized"]),
                                    /* edit */state[/* edit */7],
                                    /* addVessels */state[/* addVessels */8]
                                  ],
                                  (function (_self) {
                                      return Curry._1(store[/* logout */2], /* () */0);
                                    })
                                ]);
                      }
                    } else {
                      return /* Update */Block.__(0, [/* record */[
                                  /* radiosFilter */state[/* radiosFilter */0],
                                  /* promptEditVisible */state[/* promptEditVisible */1],
                                  /* promptVesselsVisible */state[/* promptVesselsVisible */2],
                                  /* email */state[/* email */3],
                                  /* name */state[/* name */4],
                                  /* selectedRadios */state[/* selectedRadios */5],
                                  /* radios : Done */Block.__(1, [match[0]]),
                                  /* edit */state[/* edit */7],
                                  /* addVessels */state[/* addVessels */8]
                                ]]);
                    }
                case 9 : 
                    var match$2 = action[0];
                    if (typeof match$2 === "number") {
                      return /* UpdateWithSideEffects */Block.__(2, [
                                /* record */[
                                  /* radiosFilter */state[/* radiosFilter */0],
                                  /* promptEditVisible */state[/* promptEditVisible */1],
                                  /* promptVesselsVisible */state[/* promptVesselsVisible */2],
                                  /* email */state[/* email */3],
                                  /* name */state[/* name */4],
                                  /* selectedRadios */state[/* selectedRadios */5],
                                  /* radios */state[/* radios */6],
                                  /* edit : Loading */1,
                                  /* addVessels */state[/* addVessels */8]
                                ],
                                (function (self) {
                                    UserData.updateCustomerDetails(customer[/* uuid */2], state[/* email */3], state[/* name */4]).then((function (result) {
                                            return Promise.resolve(Curry._1(self[/* send */3], /* Edit */Block.__(9, [result])));
                                          }));
                                    return /* () */0;
                                  })
                              ]);
                    } else if (match$2.tag) {
                      var match$3 = match$2[0];
                      if (match$3) {
                        return /* Update */Block.__(0, [/* record */[
                                    /* radiosFilter */state[/* radiosFilter */0],
                                    /* promptEditVisible */state[/* promptEditVisible */1],
                                    /* promptVesselsVisible */state[/* promptVesselsVisible */2],
                                    /* email */state[/* email */3],
                                    /* name */state[/* name */4],
                                    /* selectedRadios */state[/* selectedRadios */5],
                                    /* radios */state[/* radios */6],
                                    /* edit : Failed */Block.__(0, [match$3[0]]),
                                    /* addVessels */state[/* addVessels */8]
                                  ]]);
                      } else {
                        return /* UpdateWithSideEffects */Block.__(2, [
                                  /* record */[
                                    /* radiosFilter */state[/* radiosFilter */0],
                                    /* promptEditVisible */state[/* promptEditVisible */1],
                                    /* promptVesselsVisible */state[/* promptVesselsVisible */2],
                                    /* email */state[/* email */3],
                                    /* name */state[/* name */4],
                                    /* selectedRadios */state[/* selectedRadios */5],
                                    /* radios */state[/* radios */6],
                                    /* edit : Failed */Block.__(0, ["Unauthorized"]),
                                    /* addVessels */state[/* addVessels */8]
                                  ],
                                  (function (_self) {
                                      return Curry._1(store[/* logout */2], /* () */0);
                                    })
                                ]);
                      }
                    } else {
                      return /* UpdateWithSideEffects */Block.__(2, [
                                /* record */[
                                  /* radiosFilter */state[/* radiosFilter */0],
                                  /* promptEditVisible */state[/* promptEditVisible */1],
                                  /* promptVesselsVisible */state[/* promptVesselsVisible */2],
                                  /* email */state[/* email */3],
                                  /* name */state[/* name */4],
                                  /* selectedRadios */state[/* selectedRadios */5],
                                  /* radios */state[/* radios */6],
                                  /* edit : Done */Block.__(1, [match$2[0]]),
                                  /* addVessels */state[/* addVessels */8]
                                ],
                                (function (param) {
                                    return Curry._1(onEdit, /* () */0);
                                  })
                              ]);
                    }
                case 10 : 
                    var match$4 = action[0];
                    if (typeof match$4 === "number") {
                      return /* UpdateWithSideEffects */Block.__(2, [
                                /* record */[
                                  /* radiosFilter */state[/* radiosFilter */0],
                                  /* promptEditVisible */state[/* promptEditVisible */1],
                                  /* promptVesselsVisible */state[/* promptVesselsVisible */2],
                                  /* email */state[/* email */3],
                                  /* name */state[/* name */4],
                                  /* selectedRadios */state[/* selectedRadios */5],
                                  /* radios */state[/* radios */6],
                                  /* edit */state[/* edit */7],
                                  /* addVessels : Loading */1
                                ],
                                (function (self) {
                                    UserData.addCustomerVessels(customer[/* uuid */2], List.map((function (x) {
                                                  return x[/* uuid */3];
                                                }), self[/* state */1][/* selectedRadios */5])).then((function (result) {
                                            return Promise.resolve(Curry._1(self[/* send */3], /* Edit */Block.__(9, [result])));
                                          }));
                                    return /* () */0;
                                  })
                              ]);
                    } else if (match$4.tag) {
                      var match$5 = match$4[0];
                      if (match$5) {
                        return /* Update */Block.__(0, [/* record */[
                                    /* radiosFilter */state[/* radiosFilter */0],
                                    /* promptEditVisible */state[/* promptEditVisible */1],
                                    /* promptVesselsVisible */state[/* promptVesselsVisible */2],
                                    /* email */state[/* email */3],
                                    /* name */state[/* name */4],
                                    /* selectedRadios */state[/* selectedRadios */5],
                                    /* radios */state[/* radios */6],
                                    /* edit */state[/* edit */7],
                                    /* addVessels : Failed */Block.__(0, [match$5[0]])
                                  ]]);
                      } else {
                        return /* UpdateWithSideEffects */Block.__(2, [
                                  /* record */[
                                    /* radiosFilter */state[/* radiosFilter */0],
                                    /* promptEditVisible */state[/* promptEditVisible */1],
                                    /* promptVesselsVisible */state[/* promptVesselsVisible */2],
                                    /* email */state[/* email */3],
                                    /* name */state[/* name */4],
                                    /* selectedRadios */state[/* selectedRadios */5],
                                    /* radios */state[/* radios */6],
                                    /* edit */state[/* edit */7],
                                    /* addVessels : Failed */Block.__(0, ["Unauthorized"])
                                  ],
                                  (function (_self) {
                                      return Curry._1(store[/* logout */2], /* () */0);
                                    })
                                ]);
                      }
                    } else {
                      return /* UpdateWithSideEffects */Block.__(2, [
                                /* record */[
                                  /* radiosFilter */state[/* radiosFilter */0],
                                  /* promptEditVisible */state[/* promptEditVisible */1],
                                  /* promptVesselsVisible */state[/* promptVesselsVisible */2],
                                  /* email */state[/* email */3],
                                  /* name */state[/* name */4],
                                  /* selectedRadios */state[/* selectedRadios */5],
                                  /* radios */state[/* radios */6],
                                  /* edit */state[/* edit */7],
                                  /* addVessels : Done */Block.__(1, [match$4[0]])
                                ],
                                (function (param) {
                                    return Curry._1(onEdit, /* () */0);
                                  })
                              ]);
                    }
                
              }
            }),
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

exports.str = str;
exports.component = component;
exports.make = make;
/* component Not a pure module */

import React, { Component } from 'react';
import ReactDOM from 'react-dom';


const trackLengthToReadable = (trackLength) => {
    switch (trackLength) {
        case '3hours': return '3 h';
        case '6hours': return '6 h';
        case '24hours': return '24 h';
        case 'lastweek': return '1 week';
        default: return '';
    }
};

export default class TrackLengthSelector extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            selectedTrackLength: this.props.initialtracklength
        }
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    handleClickOutside = event => {
        const domNode = ReactDOM.findDOMNode(this);

        if (!domNode || !domNode.contains(event.target)) {
            this.setState({
                visible: false
            });
        }
    }

    trackLengthSelected(trackLength) {
        this.setState({selectedTrackLength: trackLength});
        this.props.onTrackLengthSelected(trackLength);
    }

    render() {
        return (
            <div className="map-legend track-length-selector">
            <div 
                  onClick={(e) => {this.setState({visible: !this.state.visible})}}
                  className="map-legend-header">
                  Track length: {trackLengthToReadable(this.state.selectedTrackLength)} {(this.state.visible && <i style={{float: 'right'}} className="fa fa-chevron-up"/>) || <i style={{float: 'right'}} className="fa fa-chevron-down"/>}
              </div>
              {this.state.visible && <div className="map-legend-body">
                <div onClick={(_) => {this.trackLengthSelected('3hours')}} className="tracklen-item clickable"><i className={`fa ${this.state.selectedTrackLength === '3hours' && "fa-circle"}`}></i><span>3 h</span></div>
                <div onClick={(_) => {this.trackLengthSelected('6hours')}} className="tracklen-item clickable"><i className={`fa ${this.state.selectedTrackLength === '6hours' && "fa-circle"}`}></i><span>6 h</span></div>
                <div onClick={(_) => {this.trackLengthSelected('24hours')}} className="tracklen-item clickable"><i className={`fa ${this.state.selectedTrackLength === '24hours' && "fa-circle"}`}></i><span>24 h</span></div>
                <div onClick={(_) => {this.trackLengthSelected('lastweek')}} className="tracklen-item clickable"><i className={`fa ${this.state.selectedTrackLength === 'lastweek' && "fa-circle"}`}></i><span>1 week</span></div>
              </div>}
            </div>
        );
    }
}

// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var $$Storage = require("./Util/Storage");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.js");

function isKnlAdmin(authStatus) {
  var permissions = List.map((function (x) {
          return x[/* permissionObject */0];
        }), authStatus[/* data */0][/* permissions */1]);
  return List.mem("****", permissions);
}

var component = ReasonReact.reducerComponent("GlobalStore");

function make(render, _children) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function (self) {
              return Curry._1(render, /* record */[
                          /* state */self[/* state */1],
                          /* dispatch */(function (action) {
                              return Curry._1(self[/* send */3], action);
                            }),
                          /* logout */(function (param) {
                              return Curry._1(self[/* send */3], /* LoggedOut */0);
                            })
                        ]);
            }),
          /* initialState */(function (param) {
              return /* record */[
                      /* loggedIn */$$Storage.getBoolFromStorage("GlobalStore.loggedIn", false),
                      /* username */$$Storage.getStringFromStorage("GlobalStore.username", ""),
                      /* email */$$Storage.getStringFromStorage("GlobalStore.email", ""),
                      /* uuid */$$Storage.getStringFromStorage("GlobalStore.uuid", ""),
                      /* name */$$Storage.getStringFromStorage("GlobalStore.name", ""),
                      /* customer */$$Storage.getStringFromStorage("GlobalStore.customer", ""),
                      /* knlAdmin */$$Storage.getBoolFromStorage("GlobalStore.knlAdmin", false)
                    ];
            }),
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */(function (action, state) {
              if (typeof action === "number") {
                return /* UpdateWithSideEffects */Block.__(2, [
                          /* record */[
                            /* loggedIn */false,
                            /* username */"",
                            /* email */"",
                            /* uuid */"",
                            /* name */"",
                            /* customer */"",
                            /* knlAdmin */false
                          ],
                          (function (_self) {
                              $$Storage.setBoolToStorage("GlobalStore.knlAdmin", false);
                              $$Storage.setStringToStorage("GlobalStore.username", "");
                              $$Storage.setBoolToStorage("GlobalStore.loggedIn", false);
                              $$Storage.setStringToStorage("GlobalStore.email", "");
                              $$Storage.setStringToStorage("GlobalStore.uuid", "");
                              $$Storage.setStringToStorage("GlobalStore.customer", "");
                              return ReasonReactRouter.push("/login");
                            })
                        ]);
              } else if (action.tag) {
                var email = action[0];
                return /* UpdateWithSideEffects */Block.__(2, [
                          /* record */[
                            /* loggedIn */state[/* loggedIn */0],
                            /* username */state[/* username */1],
                            /* email */email,
                            /* uuid */state[/* uuid */3],
                            /* name */state[/* name */4],
                            /* customer */state[/* customer */5],
                            /* knlAdmin */state[/* knlAdmin */6]
                          ],
                          (function (_self) {
                              return $$Storage.setStringToStorage("GlobalStore.email", email);
                            })
                        ]);
              } else {
                var userStatus = action[1];
                var authStatus = action[0];
                return /* UpdateWithSideEffects */Block.__(2, [
                          /* record */[
                            /* loggedIn */true,
                            /* username */userStatus[/* data */0][/* username */5],
                            /* email */userStatus[/* data */0][/* email */2],
                            /* uuid */authStatus[/* data */0][/* user */0],
                            /* name */userStatus[/* data */0][/* name */0],
                            /* customer */userStatus[/* data */0][/* customer */8],
                            /* knlAdmin */isKnlAdmin(authStatus)
                          ],
                          (function (_self) {
                              $$Storage.setBoolToStorage("GlobalStore.knlAdmin", isKnlAdmin(authStatus));
                              $$Storage.setStringToStorage("GlobalStore.username", userStatus[/* data */0][/* username */5]);
                              $$Storage.setBoolToStorage("GlobalStore.loggedIn", true);
                              $$Storage.setStringToStorage("GlobalStore.email", userStatus[/* data */0][/* email */2]);
                              $$Storage.setStringToStorage("GlobalStore.uuid", authStatus[/* data */0][/* user */0]);
                              $$Storage.setStringToStorage("GlobalStore.name", userStatus[/* data */0][/* name */0]);
                              $$Storage.setStringToStorage("GlobalStore.customer", userStatus[/* data */0][/* customer */8]);
                              return ReasonReactRouter.push("/map");
                            })
                        ]);
              }
            }),
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

var jsComponent = ReasonReact.wrapReasonForJs(component, (function (jsProps) {
        return make(jsProps.render, /* array */[]);
      }));

exports.isKnlAdmin = isKnlAdmin;
exports.component = component;
exports.make = make;
exports.jsComponent = jsComponent;
/* component Not a pure module */

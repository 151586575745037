import React, { Component } from 'react';
import * as L from 'leaflet';
import { Map, TileLayer, Marker, Popup, ZoomControl, ScaleControl, Polyline } from 'react-leaflet'
import axios from 'axios';
import icon from 'leaflet/dist/images/marker-icon.png';
import MapLegend from '../JSComponents/MapLegend';
import TrackLengthSelector from '../JSComponents/TrackLengthSelector';
import { Portal } from "react-leaflet-portal";
import * as MapIcon from "../JSComponents/Icons";
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import iconRetina from 'leaflet/dist/images/marker-icon-2x.png';
const KNLCard = require('../Components/KNLCard.bs.js').jsComponent;
const Loading = require('../Components/Loading.bs.js').jsComponent;
const MapVesselSearchItem = require('../Components/MapVesselSearch/MapVesselSearchItem.bs.js').jsComponent;

const DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconRetinaUrl: iconRetina,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowSize: [41, 41]
});

L.Marker.prototype.options.icon = DefaultIcon;

const southWest = L.latLng(-89.98155760646617, -360);
const northEast = L.latLng(89.99346179538875, 360);
const worldBounds = L.latLngBounds(southWest, northEast);


const ago = (t) => {
  return (new Date() - t) / 1000;
};
          
const genIcon = (time, speed, deg) => {
  const t0 = ago(time);
  if (t0 < 900 && speed < 2.0) {
    return MapIcon.greenSquare(0);
  } else if (t0 < 900) {
    return MapIcon.greenArrow(deg);
  } else if (t0 < 1800 && speed < 2.0) {
    return MapIcon.yellowSquare(0);
  } else if (t0 < 1800) {
    return MapIcon.yellowArrow(deg);
  } else if (speed < 2.0) {
    return MapIcon.redSquare(0);
  } else {
    return MapIcon.redArrow(deg);
  }
};

const startLoading = (state) => {
  return {
    loadingRefcount: state.loadingRefcount + 1
  };
}

const stopLoading = (state) => {
  return {
    loadingRefcount: state.loadingRefcount - 1
  };
}

const getPids = axiosConfig => () => {
  return new Promise((resolve, reject) => {
    axios.get('/api/vessels', axiosConfig)
      .then((response) => {
        resolve(response.data.data.map(x => x.pid_code));
      }).catch(reject)
  });
};

const getStations = axiosConfig => pids => {
  return new Promise((resolve, reject) => {
    axios.get('/api/map/v1/stations?resource=' + pids.join(','), axiosConfig)
      .then((response) => {
        const data = response.data.map(x => { return {...x, dateTime: Date.parse(x.time.replace(" ", "T") + "Z")} })
        resolve(data);
      }).catch(reject);
  });
}

const getStationTrack = axiosConfig => (pid, trackLength) => {
  return new Promise((resolve, reject) => {
    axios.get('/api/map/v1/rtposition?resource=' + pid + '&preset=' + trackLength, axiosConfig)
      .then((response) => {
        resolve(response.data.map(rtpos => [rtpos.latitude, rtpos.longitude]));
      }).catch(reject)
  });
}

const makeMarker = (station, onClick) => {
  return (
    <Marker 
      icon={genIcon(station.dateTime, station.speed, station.bearing)}
      key={station.pid}
      position={[station.latitude, station.longitude]}
      ref={(element) => {station.markerElement = element}}
      onClick={onClick}>
      <Popup key={station.pid} autoPan={false}>{station.name}</Popup>
    </Marker>);
}

export default class MapComponent extends Component {

  constructor(props) {
    super(props);
    this.logout = this.props.logoutCallback;
    this.mapVesselSearch = React.createRef();
    this.stationFetchTimeout = null;
    this.cancelSource = axios.CancelToken.source();
    this.axiosConfig = {
      cancelToken: this.cancelSource.token,
      xsrfCookieName: 'x-xsrf-token',
      xsrfHeaderName: 'x-xsrf-token',
      baseURL: window.config.baseUrl,
      withCredentials: true
    };
    this.getPids = getPids(this.axiosConfig);
    this.getStations = getStations(this.axiosConfig);
    this.getStationTrack = getStationTrack(this.axiosConfig);
    this.state = {
      height: window.innerHeight,
      listStationsFilter: "",
      loadingRefcount: 0,
      mapStations: [],
      listStations: [],
      station: null,
      selectedStation: null,
      selectedStationTrack: [],
      trackLength: localStorage.getItem("KNL.MapPage.trackLength") || "24hours"
    }
  }

  getState = () => {
    return new Promise(resolve => {
      this.setState(prevState => {
        resolve(prevState);
      });
    });
  }

  updateStationTrack(station, trackLength) {
    this.setState(startLoading);
    this.getStationTrack(station.pid, trackLength)
      .then(track => this.setState({selectedStationTrack: track}))
      .then(this.setState(stopLoading))
      .catch(this.errorHandler)
  }

  stationsUpdater = stations => state => {
    // Transform stations to markers
    const markers = stations.map(station => {
      return makeMarker(station, (_) => { this.stationSelected(station); });
    });
    // Find the selected station
    const selected = stations.find(station => {
      return state.selectedStation !== null && state.selectedStation !== undefined && station.pid === state.selectedStation.pid;
    });
    return {
      mapStations: markers,
      listStations: stations,
      selectedStation: selected
    }
  }

  errorHandler = (error) => {
    console.log(error);
    this.setState(stopLoading);
    this.logout();
  }

  updateDimensions = () => {
    this.setState({height: window.innerHeight});
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    const fetchStations = () => {
      const prevSelectedStation = this.state.selectedStation;
      
      const shouldUpdateTrack = (selectedStation) => (
        (selectedStation && !prevSelectedStation) ||
        (selectedStation && (new Date(prevSelectedStation.time + " UTC") < new Date(selectedStation.time + " UTC"))));

      this.setState(startLoading);
      this.getPids()
        .then(this.getStations)
        .then(this.stationsUpdater)
        .then(updater => this.setState(updater))
        .then(this.getState)
        .then(state => shouldUpdateTrack(state.selectedStation) ? this.getStationTrack(state.selectedStation.pid, state.trackLength) : null)
        .then(track => track ? this.setState({selectedStationTrack: track}) : null)
        .then(this.setState(stopLoading))
        .then(() => this.stationFetchTimeout = setTimeout(fetchStations, 60000))
        .catch(this.errorHandler);
    };
    fetchStations();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
    if (this.stationFetchTimeout !== null || this.stationFetchTimeout !== undefined) {
      this.cancelSource.cancel('Unmount cancel');
      clearTimeout(this.stationFetchTimeout);
    }
  }

  stationSelected(station) {
    if (this.state.selectedStation === station) {
      this.setState({selectedStation: null});
    } else {
      station.markerElement.leafletElement.openPopup();
      this.refs.map.leafletElement.panTo([station.latitude, station.longitude]);
      this.setState({selectedStation: station});
      this.updateStationTrack(station, this.state.trackLength);
    }
  }

  onTrackLengthSelected = (trackLength) => {
    localStorage.setItem("KNL.MapPage.trackLength", trackLength);
    this.setState({trackLength: trackLength});
    this.updateStationTrack(this.state.selectedStation, trackLength);
  }

  render() {
    let aHeight = 0;
    if (this.mapVesselSearch.current) {
      aHeight = this.mapVesselSearch.current.clientHeight + 68;
    }
    const height = window.innerHeight - 100; 
    return (
      <div name="MapPage" className="row">
        {this.state.loadingRefcount > 0 && <Loading />}
        <div className="col-sm-12 col-md-12 col-lg-9">
          <Map 
            zoomControl={false}
            ref="map"
            style={{height: height}}
            className="map"
            center={worldBounds.getCenter()}
            maxBounds={worldBounds}
            minZoom={2}
            zoom={2}>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors" />
            {this.state.mapStations}
            <Polyline color="#5c6066" dashArray="7" weight="2" positions={this.state.selectedStationTrack}/>
            <ScaleControl position="bottomleft" />
            <ZoomControl position="bottomleft" />
            <Portal position="topleft">
              <MapLegend />
              {this.state.selectedStation && <TrackLengthSelector initialtracklength={this.state.trackLength} onTrackLengthSelected={this.onTrackLengthSelected} />}
            </Portal>
          </Map>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-3">
        <KNLCard style={{maxHeight: height}} header="MAP">
          <form ref={this.mapVesselSearch} className="map-vessel-search">
            <div>
              <fieldset>
                  <input
                    onChange={(e) => {this.setState({listStationsFilter: e.target.value})}}
                    placeholder="Search for vessel..."
                    type_="text"
                    value={this.state.listStationsFilter} />
              </fieldset>
            </div>
          </form>
          <div style={{maxHeight: height - aHeight}} className="map-vessel-search-vessels">
            {
              this.state.listStations.reduce((filtered, station) => {
                if (station.name.toLowerCase().indexOf(this.state.listStationsFilter.toLowerCase()) !== -1) {
                  filtered.push(
                    <MapVesselSearchItem 
                      onClick={(_) => {this.stationSelected(station)}}
                      selected={(this.state.selectedStation !== null) && (this.state.selectedStation !== undefined) && (this.state.selectedStation.pid === station.pid)}
                      key={station.pid}
                      name={station.name}
                      imo={station.imo}
                      timestamp={station.time}
                      dateTime={station.dateTime}
                      connectionType={station.cell_conn ? "Cellular" : "HF"}
                      latitude={station.latitude}
                      longitude={station.longitude}
                      heading={station.bearing}
                      speed={station.speed}
                      track={false}
                      file={false}
                      mail={false} />);
                }
                filtered.sort((a, b) => {
                    const textA = a.props.name.toUpperCase();
                    const textB = b.props.name.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });
                return filtered;
              }, [])
            }
          </div>
        </KNLCard>
        </div>
      </div>
    );
  }
}

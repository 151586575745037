// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReactDom = require("react-dom");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var Caml_builtin_exceptions = require("bs-platform/lib/js/caml_builtin_exceptions.js");

function str(prim) {
  return prim;
}

function mkStyle(show, top, left) {
  return {
          backgroundColor: "#414d61",
          color: "white",
          height: "100px",
          left: left,
          lineHeight: "100px",
          position: "absolute",
          textAlign: "center",
          top: top,
          visibility: show ? "visible" : "hidden",
          width: "300px",
          zIndex: "99999"
        };
}

function getTop(param) {
  return String(window.pageYOffset + ((window.innerHeight / 2 | 0) - 50 | 0) | 0) + "px";
}

function getLeft(param) {
  return String((window.innerWidth / 2 | 0) - 150 | 0) + "px";
}

function onResize(_e, self) {
  return Curry._1(self[/* send */3], /* Resize */2);
}

var component = ReasonReact.reducerComponent("Loading");

function make(_children) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */(function (self) {
              var resizeHandler = Curry._1(self[/* handle */0], onResize);
              window.addEventListener("scroll", resizeHandler);
              window.addEventListener("resize", resizeHandler);
              Curry._1(self[/* onUnmount */4], (function (param) {
                      window.removeEventListener("scroll", resizeHandler);
                      window.removeEventListener("resize", resizeHandler);
                      return /* () */0;
                    }));
              Curry._1(self[/* send */3], /* Refresh */1);
              return Curry._1(self[/* send */3], /* UpdateShowTimeoutId */Block.__(0, [setTimeout((function (param) {
                                    return Curry._1(self[/* send */3], /* ShowLoader */0);
                                  }), 200)]));
            }),
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */(function (self) {
              var match = self[/* state */1][/* blinkTimeoutId */1];
              if (match !== undefined) {
                clearTimeout(Caml_option.valFromOption(match));
              } else {
                throw [
                      Caml_builtin_exceptions.match_failure,
                      /* tuple */[
                        "Loading.re",
                        65,
                        8
                      ]
                    ];
              }
              var match$1 = self[/* state */1][/* showTimeoutId */0];
              if (match$1 !== undefined) {
                clearTimeout(Caml_option.valFromOption(match$1));
                return /* () */0;
              } else {
                throw [
                      Caml_builtin_exceptions.match_failure,
                      /* tuple */[
                        "Loading.re",
                        69,
                        8
                      ]
                    ];
              }
            }),
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function (self) {
              var html = React.createElement("div", {
                    style: mkStyle(self[/* state */1][/* show */3], self[/* state */1][/* top */4], self[/* state */1][/* left */5]),
                    name: "loading"
                  }, "Loading" + self[/* state */1][/* dots */2]);
              var match = document.getElementById("root");
              if (match == null) {
                return html;
              } else {
                return ReactDom.createPortal(html, match);
              }
            }),
          /* initialState */(function (param) {
              return /* record */[
                      /* showTimeoutId */undefined,
                      /* blinkTimeoutId */undefined,
                      /* dots */"",
                      /* show */false,
                      /* top */getTop(/* () */0),
                      /* left */getLeft(/* () */0)
                    ];
            }),
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */(function (action, state) {
              if (typeof action === "number") {
                switch (action) {
                  case 0 : 
                      return /* Update */Block.__(0, [/* record */[
                                  /* showTimeoutId */state[/* showTimeoutId */0],
                                  /* blinkTimeoutId */state[/* blinkTimeoutId */1],
                                  /* dots */state[/* dots */2],
                                  /* show */true,
                                  /* top */state[/* top */4],
                                  /* left */state[/* left */5]
                                ]]);
                  case 1 : 
                      return /* UpdateWithSideEffects */Block.__(2, [
                                /* record */[
                                  /* showTimeoutId */state[/* showTimeoutId */0],
                                  /* blinkTimeoutId */state[/* blinkTimeoutId */1],
                                  /* dots */state[/* dots */2] === "..." ? "" : state[/* dots */2] + ".",
                                  /* show */state[/* show */3],
                                  /* top */state[/* top */4],
                                  /* left */state[/* left */5]
                                ],
                                (function (self) {
                                    return Curry._1(self[/* send */3], /* UpdateBlinkTimeoutId */Block.__(1, [setTimeout((function (param) {
                                                          return Curry._1(self[/* send */3], /* Refresh */1);
                                                        }), 500)]));
                                  })
                              ]);
                  case 2 : 
                      return /* Update */Block.__(0, [/* record */[
                                  /* showTimeoutId */state[/* showTimeoutId */0],
                                  /* blinkTimeoutId */state[/* blinkTimeoutId */1],
                                  /* dots */state[/* dots */2],
                                  /* show */state[/* show */3],
                                  /* top */getTop(/* () */0),
                                  /* left */getLeft(/* () */0)
                                ]]);
                  
                }
              } else if (action.tag) {
                return /* Update */Block.__(0, [/* record */[
                            /* showTimeoutId */state[/* showTimeoutId */0],
                            /* blinkTimeoutId */Caml_option.some(action[0]),
                            /* dots */state[/* dots */2],
                            /* show */state[/* show */3],
                            /* top */state[/* top */4],
                            /* left */state[/* left */5]
                          ]]);
              } else {
                return /* Update */Block.__(0, [/* record */[
                            /* showTimeoutId */Caml_option.some(action[0]),
                            /* blinkTimeoutId */state[/* blinkTimeoutId */1],
                            /* dots */state[/* dots */2],
                            /* show */state[/* show */3],
                            /* top */state[/* top */4],
                            /* left */state[/* left */5]
                          ]]);
              }
            }),
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

var jsComponent = ReasonReact.wrapReasonForJs(component, (function (jsProps) {
        return make(/* array */[]);
      }));

exports.str = str;
exports.mkStyle = mkStyle;
exports.getTop = getTop;
exports.getLeft = getLeft;
exports.onResize = onResize;
exports.component = component;
exports.make = make;
exports.jsComponent = jsComponent;
/* component Not a pure module */

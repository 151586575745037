// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Password = require("../Util/Password");
var UserData = require("../Util/UserData");
var QueryString = require("../Util/QueryString");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.js");

function str(prim) {
  return prim;
}

function submit($$event) {
  $$event.preventDefault();
  return /* Fetch */1;
}

var component = ReasonReact.reducerComponent("ActivateAccount");

function make(_children) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */(function (self) {
              var query = QueryString.parseQueryString(window.location.search);
              var match = Js_dict.get(query, "?token");
              if (match !== undefined) {
                var match$1 = match;
                if (match$1.tag) {
                  return Curry._1(self[/* send */3], /* GoToLogin */0);
                } else {
                  return Curry._1(self[/* send */3], /* ActivateToken */Block.__(0, [match$1[0]]));
                }
              } else {
                return Curry._1(self[/* send */3], /* GoToLogin */0);
              }
            }),
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function (self) {
              var match = self[/* state */1][/* fetch */3];
              return React.createElement("div", {
                          className: "login row",
                          name: "ActivateAccount"
                        }, React.createElement("div", {
                              className: "col-lg-4 col-md-2 col-sm-1"
                            }), React.createElement("div", {
                              className: "login-content col-lg-4 col-md-8 col-sm-10"
                            }, React.createElement("form", {
                                  onSubmit: (function (e) {
                                      return Curry._1(self[/* send */3], (e.preventDefault(), /* Fetch */1));
                                    })
                                }, React.createElement("fieldset", undefined, "Welcome to KNL Networks Portal!\n                    To activate your account, please \n                    give your name and create a password. ", React.createElement("strong", undefined, Password.passwordRequirement), React.createElement("br", undefined), React.createElement("br", undefined)), React.createElement("fieldset", undefined, React.createElement("legend", undefined, "Name"), React.createElement("input", {
                                          className: "login-input",
                                          name: "activate-user-name",
                                          type: "text",
                                          value: self[/* state */1][/* name */0],
                                          onChange: (function (e) {
                                              return Curry._1(self[/* send */3], /* NameUpdate */Block.__(2, [e.target.value]));
                                            })
                                        })), React.createElement("fieldset", undefined, React.createElement("legend", undefined, "Password"), React.createElement("input", {
                                          className: "login-input",
                                          name: "activate-user-password-1",
                                          type: "password",
                                          value: self[/* state */1][/* password */1],
                                          onChange: (function (e) {
                                              return Curry._1(self[/* send */3], /* PasswordUpdate */Block.__(3, [e.target.value]));
                                            })
                                        })), React.createElement("fieldset", undefined, React.createElement("legend", undefined, "Re-type password"), React.createElement("input", {
                                          className: "login-input",
                                          name: "activate-user-password-2",
                                          type: "password",
                                          value: self[/* state */1][/* passwordCheck */2],
                                          onChange: (function (e) {
                                              return Curry._1(self[/* send */3], /* PasswordCheckUpdate */Block.__(4, [e.target.value]));
                                            })
                                        })), typeof match === "number" ? React.createElement("div", undefined) : React.createElement("div", {
                                        className: "login-error blink_me"
                                      }, match[0]), React.createElement("button", {
                                      className: "log-in-button primary",
                                      disabled: !Password.validNewPassword(self[/* state */1][/* password */1], self[/* state */1][/* passwordCheck */2]),
                                      name: "activate"
                                    }, "Submit"))));
            }),
          /* initialState */(function (param) {
              return /* record */[
                      /* name */"",
                      /* password */"",
                      /* passwordCheck */"",
                      /* fetch : NotAsked */0,
                      /* token */undefined
                    ];
            }),
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */(function (action, state) {
              if (typeof action === "number") {
                switch (action) {
                  case 0 : 
                      return /* SideEffects */Block.__(1, [(function (_self) {
                                    return ReasonReactRouter.push("/login");
                                  })]);
                  case 1 : 
                      return /* UpdateWithSideEffects */Block.__(2, [
                                /* record */[
                                  /* name */state[/* name */0],
                                  /* password */state[/* password */1],
                                  /* passwordCheck */state[/* passwordCheck */2],
                                  /* fetch : Loading */1,
                                  /* token */state[/* token */4]
                                ],
                                (function (self) {
                                    var match = state[/* token */4];
                                    if (match !== undefined) {
                                      UserData.activateUser(state[/* name */0], state[/* password */1], match).then((function (result) {
                                              if (result.tag) {
                                                return Promise.resolve(Curry._1(self[/* send */3], /* FetchFailed */Block.__(1, [result[0]])));
                                              } else {
                                                return Promise.resolve(Curry._1(self[/* send */3], /* FetchSuccess */2));
                                              }
                                            }));
                                      return /* () */0;
                                    } else {
                                      Curry._1(self[/* send */3], /* GoToLogin */0);
                                      return /* () */0;
                                    }
                                  })
                              ]);
                  case 2 : 
                      return /* UpdateWithSideEffects */Block.__(2, [
                                /* record */[
                                  /* name */state[/* name */0],
                                  /* password */state[/* password */1],
                                  /* passwordCheck */state[/* passwordCheck */2],
                                  /* fetch : Success */2,
                                  /* token */state[/* token */4]
                                ],
                                (function (self) {
                                    return Curry._1(self[/* send */3], /* GoToLogin */0);
                                  })
                              ]);
                  
                }
              } else {
                switch (action.tag | 0) {
                  case 0 : 
                      return /* Update */Block.__(0, [/* record */[
                                  /* name */state[/* name */0],
                                  /* password */state[/* password */1],
                                  /* passwordCheck */state[/* passwordCheck */2],
                                  /* fetch */state[/* fetch */3],
                                  /* token */action[0]
                                ]]);
                  case 1 : 
                      var match = action[0];
                      if (match) {
                        return /* Update */Block.__(0, [/* record */[
                                    /* name */state[/* name */0],
                                    /* password */state[/* password */1],
                                    /* passwordCheck */state[/* passwordCheck */2],
                                    /* fetch : Failure */[match[0]],
                                    /* token */state[/* token */4]
                                  ]]);
                      } else {
                        return /* Update */Block.__(0, [/* record */[
                                    /* name */state[/* name */0],
                                    /* password */state[/* password */1],
                                    /* passwordCheck */state[/* passwordCheck */2],
                                    /* fetch : Failure */["Unauthorized"],
                                    /* token */state[/* token */4]
                                  ]]);
                      }
                  case 2 : 
                      return /* Update */Block.__(0, [/* record */[
                                  /* name */action[0],
                                  /* password */state[/* password */1],
                                  /* passwordCheck */state[/* passwordCheck */2],
                                  /* fetch */state[/* fetch */3],
                                  /* token */state[/* token */4]
                                ]]);
                  case 3 : 
                      return /* Update */Block.__(0, [/* record */[
                                  /* name */state[/* name */0],
                                  /* password */action[0],
                                  /* passwordCheck */state[/* passwordCheck */2],
                                  /* fetch */state[/* fetch */3],
                                  /* token */state[/* token */4]
                                ]]);
                  case 4 : 
                      return /* Update */Block.__(0, [/* record */[
                                  /* name */state[/* name */0],
                                  /* password */state[/* password */1],
                                  /* passwordCheck */action[0],
                                  /* fetch */state[/* fetch */3],
                                  /* token */state[/* token */4]
                                ]]);
                  
                }
              }
            }),
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

exports.str = str;
exports.submit = submit;
exports.component = component;
exports.make = make;
/* component Not a pure module */

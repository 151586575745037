import L from 'leaflet';

const squareDimensions = [18, 18];
const arrowDimensions = [16, 24];

const greenArrowSvg = require('../mapmarker/green-arrow.svg');
const greenSquareSvg = require('../mapmarker/green-square.svg');
const yellowArrowSvg = require('../mapmarker/yellow-arrow.svg');
const yellowSquareSvg = require('../mapmarker/yellow-square.svg');
const redArrowSvg = require('../mapmarker/red-arrow.svg');
const redSquareSvg = require('../mapmarker/red-square.svg');
const whiteArrowSvg = require('../mapmarker/white-arrow.svg');
const whiteSquareSvg = require('../mapmarker/white-square.svg');

const rotateGen = (deg) => {
    return `transform: rotate(${deg}deg); \
    -moz-transform: rotate(${deg}deg); \
    -webkit-transform: rotate(${deg}deg); \
    -o-transform: rotate(${deg}deg); \
    -ms-transform: rotate(${deg}deg);`;
};

const dimensionGen = ([x, y]) => {
    return `padding: 0; margin: -${x/2}px -${y/2}px 0 0; width: ${x}px; height: ${y}px;`;
};

const iconGen = (svg, dimensions) => deg => {
    return new L.divIcon({
        className: '',
        html: `<img style="${rotateGen(deg)} ${dimensionGen(dimensions)}" src="${svg}"/>`
    });
};

const greenArrow = iconGen(greenArrowSvg, arrowDimensions);
const greenSquare = iconGen(greenSquareSvg, squareDimensions);
const yellowArrow = iconGen(yellowArrowSvg, arrowDimensions);
const yellowSquare = iconGen(yellowSquareSvg, squareDimensions);
const redArrow = iconGen(redArrowSvg, arrowDimensions);
const redSquare = iconGen(redSquareSvg, squareDimensions);
const whiteArrow = iconGen(whiteArrowSvg, arrowDimensions);
const whiteSquare = iconGen(whiteSquareSvg, squareDimensions);

export {
    greenArrow,
    greenSquare,
    yellowArrow,
    yellowSquare,
    redArrow,
    redSquare,
    whiteArrow,
    whiteSquare
};
// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$String = require("bs-platform/lib/js/string.js");
var UserData = require("../Util/UserData");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.js");

function str(prim) {
  return prim;
}

var component = ReasonReact.reducerComponent("UserWidget");

function showHide(visible) {
  if (visible) {
    return /* Hide */0;
  } else {
    return /* Show */1;
  }
}

function make(store, _children) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function (self) {
              return React.createElement("div", {
                          className: "user-widget",
                          onBlur: (function (_e) {
                              return Curry._1(self[/* send */3], /* Hide */0);
                            }),
                          onMouseLeave: (function (_e) {
                              return Curry._1(self[/* send */3], /* Hide */0);
                            })
                        }, React.createElement("div", {
                              className: "user-widget-dropdown",
                              onClick: (function (_e) {
                                  return Curry._1(self[/* send */3], /* Show */1);
                                }),
                              onMouseEnter: (function (_e) {
                                  return Curry._1(self[/* send */3], /* Show */1);
                                })
                            }, React.createElement("i", {
                                  className: "fa fa-user-o"
                                }), React.createElement("span", {
                                  className: "username-container"
                                }, " " + ($$String.uppercase(store[/* state */0][/* username */1]) + " ")), React.createElement("i", {
                                  className: "fa fa-chevron-down"
                                })), self[/* state */1][/* visible */0] ? React.createElement("div", {
                                className: "user-widget-dropdown-menu"
                              }, React.createElement("div", {
                                    className: "user-widget-dropdown-link",
                                    name: "profile",
                                    onClick: (function (_e) {
                                        return ReasonReactRouter.push("/profile");
                                      })
                                  }, "EDIT PROFILE"), React.createElement("div", {
                                    className: "user-widget-dropdown-link",
                                    name: "logout",
                                    onClick: (function (_e) {
                                        return Curry._1(self[/* send */3], /* Logout */2);
                                      })
                                  }, "LOG OUT")) : React.createElement("div", undefined));
            }),
          /* initialState */(function (param) {
              return /* record */[/* visible */false];
            }),
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */(function (action, state) {
              switch (action) {
                case 0 : 
                    return /* Update */Block.__(0, [/* record */[/* visible */false]]);
                case 1 : 
                    return /* Update */Block.__(0, [/* record */[/* visible */true]]);
                case 2 : 
                    return /* SideEffects */Block.__(1, [(function (self) {
                                  UserData.logout(/* () */0).then((function (_result) {
                                          return Promise.resolve(Curry._1(self[/* send */3], /* GoToLogin */3));
                                        }));
                                  return /* () */0;
                                })]);
                case 3 : 
                    return /* SideEffects */Block.__(1, [(function (self) {
                                  Curry._1(store[/* dispatch */1], /* LoggedOut */0);
                                  return Curry._1(self[/* send */3], /* Hide */0);
                                })]);
                
              }
            }),
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

exports.str = str;
exports.component = component;
exports.showHide = showHide;
exports.make = make;
/* component Not a pure module */

// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE
'use strict';


function validNewPassword(password1, password2) {
  if (password1.length >= 12) {
    return password1 === password2;
  } else {
    return false;
  }
}

var passwordRequirement = "Please note that the password must be at least 12 characters.";

exports.passwordRequirement = passwordRequirement;
exports.validNewPassword = validNewPassword;
/* No side effect */

// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var React = require("react");
var UserData = require("../Util/UserData");
var ReasonReact = require("reason-react/src/ReasonReact.js");

function str(prim) {
  return prim;
}

var component = ReasonReact.reducerComponent("CustomerVesselList");

function make(customer, _children) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function (self) {
              console.log(self[/* state */1][/* vessels */0]);
              return React.createElement("div", undefined, React.createElement("table", undefined, React.createElement("tbody", undefined, $$Array.of_list(List.map((function (x) {
                                            return React.createElement("tr", {
                                                        key: x[/* uuid */3]
                                                      }, React.createElement("td", undefined, x[/* name */5]), React.createElement("td", {
                                                            style: {
                                                              textAlign: "right"
                                                            }
                                                          }, "#" + UserData.getPrintableSid(x)));
                                          }), customer[/* vessels */4])))));
            }),
          /* initialState */(function (param) {
              return /* record */[/* vessels : :: */[
                        /* tuple */[
                          "kissa",
                          32
                        ],
                        /* :: */[
                          /* tuple */[
                            "koira",
                            23
                          ],
                          /* :: */[
                            /* tuple */[
                              "apina",
                              4
                            ],
                            /* :: */[
                              /* tuple */[
                                "aasi",
                                91
                              ],
                              /* [] */0
                            ]
                          ]
                        ]
                      ]];
            }),
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */(function (action, state) {
              return /* NoUpdate */0;
            }),
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

exports.str = str;
exports.component = component;
exports.make = make;
/* component Not a pure module */

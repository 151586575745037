// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var User = require("./User");
var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Dialog = require("../Components/Dialog");
var KNLCard = require("../Components/KNLCard");
var Loading = require("../Components/Loading");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var UserData = require("../Util/UserData");
var ReasonReact = require("reason-react/src/ReasonReact.js");

function str(prim) {
  return prim;
}

function isValidEmail(email) {
  return (/\S+@\S+\.\S+/).test(email);
}

var component = ReasonReact.reducerComponent("User");

function make(store, _children) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */(function (self) {
              return Curry._1(self[/* send */3], /* UsersFetch */1);
            }),
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function (self) {
              var match = self[/* state */1][/* deleteUser */6];
              var tmp;
              if (match !== undefined) {
                var user = match;
                tmp = ReasonReact.element(undefined, undefined, Dialog.make(300, 350, (function (param) {
                            return Curry._1(self[/* send */3], /* DeleteUser */Block.__(4, [user]));
                          }), (function (param) {
                            return Curry._1(self[/* send */3], /* CancelDeleteUser */3);
                          }), /* array */[React.createElement("div", {
                                className: "user-page-dialog"
                              }, React.createElement("p", undefined, "You are about to delete the user profile"), React.createElement("p", undefined, React.createElement("strong", undefined, user[/* name */0])), React.createElement("p", undefined, "This will remove this user's access to the portal"), React.createElement("p", undefined, React.createElement("strong", undefined, "This action cannot be undone after the profile has been deleted.")), React.createElement("p", undefined, React.createElement("strong", undefined, "Are you sure?")))]));
              } else {
                tmp = React.createElement("div", undefined);
              }
              var match$1 = self[/* state */1][/* userList */0];
              var tmp$1;
              tmp$1 = typeof match$1 === "number" || match$1.tag ? React.createElement("div", undefined) : $$Array.of_list(List.map((function (item) {
                            return ReasonReact.element(String(item[/* id */3]), undefined, User.make(Caml_obj.caml_equal(self[/* state */1][/* selectedUser */4], item), item[/* name */0], item[/* email */2], item[/* username */5], (function (param) {
                                              return Curry._1(self[/* send */3], /* SelectedUser */Block.__(8, [item]));
                                            }), (function (param) {
                                              return Curry._1(self[/* send */3], /* SetDeleteUser */Block.__(6, [item]));
                                            }), item[/* active */1], /* array */[]));
                          }), List.filter((function (user) {
                                  if (self[/* state */1][/* userlistFilter */1] === "") {
                                    return true;
                                  } else {
                                    return user[/* name */0].toLowerCase().indexOf(self[/* state */1][/* userlistFilter */1].toLowerCase()) === 0;
                                  }
                                }))(match$1[0][/* data */0])));
              var tmp$2;
              if (self[/* state */1][/* userInviteVisible */3]) {
                var match$2 = self[/* state */1][/* inviteUserFetch */8];
                var tmp$3;
                tmp$3 = typeof match$2 === "number" || match$2.tag !== 1 ? React.createElement("div", undefined) : React.createElement("div", {
                        className: "settings-error"
                      }, match$2[0]);
                tmp$2 = React.createElement("div", {
                      className: "col-lg-4 col-md-10 col-sm-12"
                    }, ReasonReact.element(undefined, undefined, KNLCard.make(undefined, "INVITE A NEW USER", /* array */[React.createElement("div", {
                                    className: "settings"
                                  }, React.createElement("div", {
                                        className: "content"
                                      }, "\n                                You can pre-fill the information \n                                about a new user and send them \n                                an invite to the email address \n                                specified below. The new user \n                                can follow the instructions \n                                in the email to accept the \n                                invitation and to set their \n                                password."), React.createElement("div", {
                                        className: "content"
                                      }, React.createElement("form", {
                                            className: "settings-form"
                                          }, React.createElement("fieldset", undefined, React.createElement("label", undefined, "Email"), React.createElement("input", {
                                                    name: "email",
                                                    type: "email",
                                                    value: self[/* state */1][/* newUserEmail */2],
                                                    onChange: (function (e) {
                                                        return Curry._1(self[/* send */3], /* EmailUpdate */Block.__(10, [e.target.value]));
                                                      })
                                                  })), React.createElement("div", {
                                                className: "settings-bottom"
                                              }, tmp$3, React.createElement("button", {
                                                    className: "tertiary",
                                                    disabled: !isValidEmail(self[/* state */1][/* newUserEmail */2]),
                                                    name: "confirm",
                                                    onClick: (function (e) {
                                                        e.preventDefault();
                                                        return Curry._1(self[/* send */3], /* SetInvitePrompt */Block.__(7, [true]));
                                                      })
                                                  }, "CONFIRM")))))])));
              } else {
                tmp$2 = React.createElement("div", undefined);
              }
              return React.createElement("div", {
                          className: "row",
                          name: "Users"
                        }, self[/* state */1][/* userList */0] === /* Fetching */1 || self[/* state */1][/* deleteUserFetch */7] === /* Fetching */1 ? ReasonReact.element(undefined, undefined, Loading.make(/* array */[])) : React.createElement("div", undefined), self[/* state */1][/* invitePromptVisible */5] ? ReasonReact.element(undefined, undefined, Dialog.make(300, 220, (function (param) {
                                      return Curry._1(self[/* send */3], /* UserInviteFetch */0);
                                    }), (function (param) {
                                      return Curry._1(self[/* send */3], /* SetInvitePrompt */Block.__(7, [false]));
                                    }), /* array */[React.createElement("div", {
                                          className: "user-page-dialog"
                                        }, React.createElement("p", undefined, "You are about to invite a new user with email address"), React.createElement("p", undefined, React.createElement("strong", undefined, self[/* state */1][/* newUserEmail */2])), React.createElement("p", undefined, React.createElement("strong", undefined, "Are you sure?")))])) : React.createElement("div", undefined), tmp, React.createElement("div", {
                              className: "col-lg-4 col-md-10 col-sm-12"
                            }, ReasonReact.element(undefined, undefined, KNLCard.make(undefined, "USERS", /* array */[
                                      React.createElement("form", {
                                            className: "map-vessel-search"
                                          }, React.createElement("div", undefined, React.createElement("fieldset", undefined, React.createElement("input", {
                                                        placeholder: "Filter users...",
                                                        type: "text",
                                                        value: self[/* state */1][/* userlistFilter */1],
                                                        onChange: (function (e) {
                                                            return Curry._1(self[/* send */3], /* UserListFilterUpdate */Block.__(9, [e.target.value]));
                                                          })
                                                      })))),
                                      React.createElement("button", {
                                            className: "primary",
                                            name: "invite",
                                            onClick: (function (param) {
                                                return Curry._1(self[/* send */3], /* ToggleUserInvite */4);
                                              })
                                          }, "Invite a new user"),
                                      React.createElement("div", {
                                            className: "user-list"
                                          }, tmp$1)
                                    ]))), tmp$2);
            }),
          /* initialState */(function (param) {
              return /* record */[
                      /* userList : Idle */0,
                      /* userlistFilter */"",
                      /* newUserEmail */"",
                      /* userInviteVisible */false,
                      /* selectedUser */undefined,
                      /* invitePromptVisible */false,
                      /* deleteUser */undefined,
                      /* deleteUserFetch : Idle */0,
                      /* inviteUserFetch : Idle */0
                    ];
            }),
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */(function (action, state) {
              if (typeof action === "number") {
                switch (action) {
                  case 0 : 
                      return /* UpdateWithSideEffects */Block.__(2, [
                                /* record */[
                                  /* userList */state[/* userList */0],
                                  /* userlistFilter */state[/* userlistFilter */1],
                                  /* newUserEmail */state[/* newUserEmail */2],
                                  /* userInviteVisible */state[/* userInviteVisible */3],
                                  /* selectedUser */state[/* selectedUser */4],
                                  /* invitePromptVisible */false,
                                  /* deleteUser */state[/* deleteUser */6],
                                  /* deleteUserFetch */state[/* deleteUserFetch */7],
                                  /* inviteUserFetch : Fetching */1
                                ],
                                (function (self) {
                                    UserData.inviteUser(self[/* state */1][/* newUserEmail */2]).then((function (result) {
                                            if (result.tag) {
                                              return Promise.resolve(Curry._1(self[/* send */3], /* UserInviteFetchFailed */Block.__(1, [result[0]])));
                                            } else {
                                              return Promise.resolve(Curry._1(self[/* send */3], /* UserInviteFetched */Block.__(0, [result[0]])));
                                            }
                                          }));
                                    return /* () */0;
                                  })
                              ]);
                  case 1 : 
                      return /* UpdateWithSideEffects */Block.__(2, [
                                /* record */[
                                  /* userList : Fetching */1,
                                  /* userlistFilter */state[/* userlistFilter */1],
                                  /* newUserEmail */state[/* newUserEmail */2],
                                  /* userInviteVisible */state[/* userInviteVisible */3],
                                  /* selectedUser */state[/* selectedUser */4],
                                  /* invitePromptVisible */state[/* invitePromptVisible */5],
                                  /* deleteUser */state[/* deleteUser */6],
                                  /* deleteUserFetch */state[/* deleteUserFetch */7],
                                  /* inviteUserFetch */state[/* inviteUserFetch */8]
                                ],
                                (function (self) {
                                    UserData.getUsers(/* () */0).then((function (result) {
                                            if (result.tag) {
                                              return Promise.resolve(Curry._1(self[/* send */3], /* UsersFetchFailed */Block.__(3, [result[0]])));
                                            } else {
                                              return Promise.resolve(Curry._1(self[/* send */3], /* UsersFetched */Block.__(2, [result[0]])));
                                            }
                                          }));
                                    return /* () */0;
                                  })
                              ]);
                  case 2 : 
                      return /* UpdateWithSideEffects */Block.__(2, [
                                /* record */[
                                  /* userList */state[/* userList */0],
                                  /* userlistFilter */state[/* userlistFilter */1],
                                  /* newUserEmail */state[/* newUserEmail */2],
                                  /* userInviteVisible */state[/* userInviteVisible */3],
                                  /* selectedUser */state[/* selectedUser */4],
                                  /* invitePromptVisible */state[/* invitePromptVisible */5],
                                  /* deleteUser */undefined,
                                  /* deleteUserFetch : Done */Block.__(0, ["ok"]),
                                  /* inviteUserFetch */state[/* inviteUserFetch */8]
                                ],
                                (function (self) {
                                    return Curry._1(self[/* send */3], /* UsersFetch */1);
                                  })
                              ]);
                  case 3 : 
                      return /* Update */Block.__(0, [/* record */[
                                  /* userList */state[/* userList */0],
                                  /* userlistFilter */state[/* userlistFilter */1],
                                  /* newUserEmail */state[/* newUserEmail */2],
                                  /* userInviteVisible */state[/* userInviteVisible */3],
                                  /* selectedUser */state[/* selectedUser */4],
                                  /* invitePromptVisible */state[/* invitePromptVisible */5],
                                  /* deleteUser */undefined,
                                  /* deleteUserFetch */state[/* deleteUserFetch */7],
                                  /* inviteUserFetch */state[/* inviteUserFetch */8]
                                ]]);
                  case 4 : 
                      return /* Update */Block.__(0, [/* record */[
                                  /* userList */state[/* userList */0],
                                  /* userlistFilter */state[/* userlistFilter */1],
                                  /* newUserEmail */state[/* newUserEmail */2],
                                  /* userInviteVisible */!state[/* userInviteVisible */3],
                                  /* selectedUser */state[/* selectedUser */4],
                                  /* invitePromptVisible */state[/* invitePromptVisible */5],
                                  /* deleteUser */state[/* deleteUser */6],
                                  /* deleteUserFetch */state[/* deleteUserFetch */7],
                                  /* inviteUserFetch */state[/* inviteUserFetch */8]
                                ]]);
                  
                }
              } else {
                switch (action.tag | 0) {
                  case 0 : 
                      return /* UpdateWithSideEffects */Block.__(2, [
                                /* record */[
                                  /* userList */state[/* userList */0],
                                  /* userlistFilter */state[/* userlistFilter */1],
                                  /* newUserEmail */"",
                                  /* userInviteVisible */state[/* userInviteVisible */3],
                                  /* selectedUser */state[/* selectedUser */4],
                                  /* invitePromptVisible */state[/* invitePromptVisible */5],
                                  /* deleteUser */state[/* deleteUser */6],
                                  /* deleteUserFetch */state[/* deleteUserFetch */7],
                                  /* inviteUserFetch : Done */Block.__(0, [action[0]])
                                ],
                                (function (self) {
                                    return Curry._1(self[/* send */3], /* UsersFetch */1);
                                  })
                              ]);
                  case 1 : 
                      var match = action[0];
                      if (match) {
                        return /* Update */Block.__(0, [/* record */[
                                    /* userList */state[/* userList */0],
                                    /* userlistFilter */state[/* userlistFilter */1],
                                    /* newUserEmail */state[/* newUserEmail */2],
                                    /* userInviteVisible */state[/* userInviteVisible */3],
                                    /* selectedUser */state[/* selectedUser */4],
                                    /* invitePromptVisible */state[/* invitePromptVisible */5],
                                    /* deleteUser */state[/* deleteUser */6],
                                    /* deleteUserFetch */state[/* deleteUserFetch */7],
                                    /* inviteUserFetch : Failed */Block.__(1, [match[0]])
                                  ]]);
                      } else {
                        return /* Update */Block.__(0, [/* record */[
                                    /* userList */state[/* userList */0],
                                    /* userlistFilter */state[/* userlistFilter */1],
                                    /* newUserEmail */state[/* newUserEmail */2],
                                    /* userInviteVisible */state[/* userInviteVisible */3],
                                    /* selectedUser */state[/* selectedUser */4],
                                    /* invitePromptVisible */state[/* invitePromptVisible */5],
                                    /* deleteUser */state[/* deleteUser */6],
                                    /* deleteUserFetch */state[/* deleteUserFetch */7],
                                    /* inviteUserFetch : Failed */Block.__(1, ["User with the given email address already exists in the system."])
                                  ]]);
                      }
                  case 2 : 
                      return /* Update */Block.__(0, [/* record */[
                                  /* userList : Done */Block.__(0, [action[0]]),
                                  /* userlistFilter */state[/* userlistFilter */1],
                                  /* newUserEmail */state[/* newUserEmail */2],
                                  /* userInviteVisible */state[/* userInviteVisible */3],
                                  /* selectedUser */state[/* selectedUser */4],
                                  /* invitePromptVisible */state[/* invitePromptVisible */5],
                                  /* deleteUser */state[/* deleteUser */6],
                                  /* deleteUserFetch */state[/* deleteUserFetch */7],
                                  /* inviteUserFetch */state[/* inviteUserFetch */8]
                                ]]);
                  case 3 : 
                      var match$1 = action[0];
                      if (match$1) {
                        return /* Update */Block.__(0, [/* record */[
                                    /* userList : Failed */Block.__(1, [match$1[0]]),
                                    /* userlistFilter */state[/* userlistFilter */1],
                                    /* newUserEmail */state[/* newUserEmail */2],
                                    /* userInviteVisible */state[/* userInviteVisible */3],
                                    /* selectedUser */state[/* selectedUser */4],
                                    /* invitePromptVisible */state[/* invitePromptVisible */5],
                                    /* deleteUser */state[/* deleteUser */6],
                                    /* deleteUserFetch */state[/* deleteUserFetch */7],
                                    /* inviteUserFetch */state[/* inviteUserFetch */8]
                                  ]]);
                      } else {
                        return /* UpdateWithSideEffects */Block.__(2, [
                                  /* record */[
                                    /* userList : Failed */Block.__(1, ["Unauthorized"]),
                                    /* userlistFilter */state[/* userlistFilter */1],
                                    /* newUserEmail */state[/* newUserEmail */2],
                                    /* userInviteVisible */state[/* userInviteVisible */3],
                                    /* selectedUser */state[/* selectedUser */4],
                                    /* invitePromptVisible */state[/* invitePromptVisible */5],
                                    /* deleteUser */state[/* deleteUser */6],
                                    /* deleteUserFetch */state[/* deleteUserFetch */7],
                                    /* inviteUserFetch */state[/* inviteUserFetch */8]
                                  ],
                                  (function (_self) {
                                      return Curry._1(store[/* dispatch */1], /* LoggedOut */0);
                                    })
                                ]);
                      }
                  case 4 : 
                      var user = action[0];
                      return /* UpdateWithSideEffects */Block.__(2, [
                                /* record */[
                                  /* userList */state[/* userList */0],
                                  /* userlistFilter */state[/* userlistFilter */1],
                                  /* newUserEmail */state[/* newUserEmail */2],
                                  /* userInviteVisible */state[/* userInviteVisible */3],
                                  /* selectedUser */state[/* selectedUser */4],
                                  /* invitePromptVisible */state[/* invitePromptVisible */5],
                                  /* deleteUser */state[/* deleteUser */6],
                                  /* deleteUserFetch : Fetching */1,
                                  /* inviteUserFetch */state[/* inviteUserFetch */8]
                                ],
                                (function (self) {
                                    UserData.deleteUser(user[/* uuid */6]).then((function (result) {
                                            if (result.tag) {
                                              return Promise.resolve(Curry._1(self[/* send */3], /* UserDeletionFailed */Block.__(5, [result[0]])));
                                            } else {
                                              return Promise.resolve(Curry._1(self[/* send */3], /* UserDeleted */2));
                                            }
                                          }));
                                    return /* () */0;
                                  })
                              ]);
                  case 5 : 
                      var match$2 = action[0];
                      if (match$2) {
                        return /* Update */Block.__(0, [/* record */[
                                    /* userList */state[/* userList */0],
                                    /* userlistFilter */state[/* userlistFilter */1],
                                    /* newUserEmail */state[/* newUserEmail */2],
                                    /* userInviteVisible */state[/* userInviteVisible */3],
                                    /* selectedUser */undefined,
                                    /* invitePromptVisible */state[/* invitePromptVisible */5],
                                    /* deleteUser */undefined,
                                    /* deleteUserFetch : Failed */Block.__(1, [match$2[0]]),
                                    /* inviteUserFetch */state[/* inviteUserFetch */8]
                                  ]]);
                      } else {
                        return /* UpdateWithSideEffects */Block.__(2, [
                                  /* record */[
                                    /* userList */state[/* userList */0],
                                    /* userlistFilter */state[/* userlistFilter */1],
                                    /* newUserEmail */state[/* newUserEmail */2],
                                    /* userInviteVisible */state[/* userInviteVisible */3],
                                    /* selectedUser */undefined,
                                    /* invitePromptVisible */state[/* invitePromptVisible */5],
                                    /* deleteUser */undefined,
                                    /* deleteUserFetch : Failed */Block.__(1, ["Unauthorized"]),
                                    /* inviteUserFetch */state[/* inviteUserFetch */8]
                                  ],
                                  (function (_self) {
                                      return Curry._1(store[/* dispatch */1], /* LoggedOut */0);
                                    })
                                ]);
                      }
                  case 6 : 
                      return /* Update */Block.__(0, [/* record */[
                                  /* userList */state[/* userList */0],
                                  /* userlistFilter */state[/* userlistFilter */1],
                                  /* newUserEmail */state[/* newUserEmail */2],
                                  /* userInviteVisible */state[/* userInviteVisible */3],
                                  /* selectedUser */state[/* selectedUser */4],
                                  /* invitePromptVisible */state[/* invitePromptVisible */5],
                                  /* deleteUser */action[0],
                                  /* deleteUserFetch */state[/* deleteUserFetch */7],
                                  /* inviteUserFetch */state[/* inviteUserFetch */8]
                                ]]);
                  case 7 : 
                      return /* Update */Block.__(0, [/* record */[
                                  /* userList */state[/* userList */0],
                                  /* userlistFilter */state[/* userlistFilter */1],
                                  /* newUserEmail */state[/* newUserEmail */2],
                                  /* userInviteVisible */state[/* userInviteVisible */3],
                                  /* selectedUser */state[/* selectedUser */4],
                                  /* invitePromptVisible */action[0],
                                  /* deleteUser */state[/* deleteUser */6],
                                  /* deleteUserFetch */state[/* deleteUserFetch */7],
                                  /* inviteUserFetch */state[/* inviteUserFetch */8]
                                ]]);
                  case 8 : 
                      return /* Update */Block.__(0, [/* record */[
                                  /* userList */state[/* userList */0],
                                  /* userlistFilter */state[/* userlistFilter */1],
                                  /* newUserEmail */state[/* newUserEmail */2],
                                  /* userInviteVisible */state[/* userInviteVisible */3],
                                  /* selectedUser */action[0],
                                  /* invitePromptVisible */state[/* invitePromptVisible */5],
                                  /* deleteUser */state[/* deleteUser */6],
                                  /* deleteUserFetch */state[/* deleteUserFetch */7],
                                  /* inviteUserFetch */state[/* inviteUserFetch */8]
                                ]]);
                  case 9 : 
                      return /* Update */Block.__(0, [/* record */[
                                  /* userList */state[/* userList */0],
                                  /* userlistFilter */action[0],
                                  /* newUserEmail */state[/* newUserEmail */2],
                                  /* userInviteVisible */state[/* userInviteVisible */3],
                                  /* selectedUser */state[/* selectedUser */4],
                                  /* invitePromptVisible */state[/* invitePromptVisible */5],
                                  /* deleteUser */state[/* deleteUser */6],
                                  /* deleteUserFetch */state[/* deleteUserFetch */7],
                                  /* inviteUserFetch */state[/* inviteUserFetch */8]
                                ]]);
                  case 10 : 
                      return /* Update */Block.__(0, [/* record */[
                                  /* userList */state[/* userList */0],
                                  /* userlistFilter */state[/* userlistFilter */1],
                                  /* newUserEmail */action[0],
                                  /* userInviteVisible */state[/* userInviteVisible */3],
                                  /* selectedUser */state[/* selectedUser */4],
                                  /* invitePromptVisible */state[/* invitePromptVisible */5],
                                  /* deleteUser */state[/* deleteUser */6],
                                  /* deleteUserFetch */state[/* deleteUserFetch */7],
                                  /* inviteUserFetch */state[/* inviteUserFetch */8]
                                ]]);
                  
                }
              }
            }),
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

exports.str = str;
exports.isValidEmail = isValidEmail;
exports.component = component;
exports.make = make;
/* component Not a pure module */

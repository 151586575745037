// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var KNLCard = require("../Components/KNLCard");
var Loading = require("../Components/Loading");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var UserData = require("../Util/UserData");
var DataUsage = require("../Components/DataUsage");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var Caml_primitive = require("bs-platform/lib/js/caml_primitive.js");

function str(prim) {
  return prim;
}

var component = ReasonReact.reducerComponent("Fleet");

function usagesToCards(param) {
  return List.map((function (item) {
                return React.createElement("div", {
                            key: item[/* billing_token */0],
                            className: "col-sm-12 col-md-12 col-lg-4"
                          }, ReasonReact.element(undefined, undefined, KNLCard.make(undefined, item[/* name */5], /* array */[
                                    ReasonReact.element(undefined, undefined, DataUsage.make(/* Sea */0, item[/* sea_usage */3], item[/* sea_allowance */1], /* array */[])),
                                    ReasonReact.element(undefined, undefined, DataUsage.make(/* Shore */1, item[/* shore_usage */4], item[/* shore_allowance */2], /* array */[]))
                                  ])));
              }), param);
}

function chunk(_$staropt$star, _$staropt$star$1, _ls) {
  while(true) {
    var ls = _ls;
    var $staropt$star = _$staropt$star$1;
    var $staropt$star$1 = _$staropt$star;
    var nLs = $staropt$star$1 !== undefined ? $staropt$star$1 : /* [] */0;
    var oLs = $staropt$star !== undefined ? $staropt$star : /* [] */0;
    var match = List.length(ls);
    var match$1 = List.length(nLs);
    if (match !== 0) {
      _ls = List.tl(ls);
      if (match$1 !== 3) {
        _$staropt$star$1 = oLs;
        _$staropt$star = /* :: */[
          List.hd(ls),
          nLs
        ];
        continue ;
      } else {
        _$staropt$star$1 = /* :: */[
          List.rev(nLs),
          oLs
        ];
        _$staropt$star = /* :: */[
          List.hd(ls),
          /* [] */0
        ];
        continue ;
      }
    } else {
      return /* :: */[
              nLs,
              oLs
            ];
    }
  };
}

function joinStrings(param) {
  return List.fold_left((function (a, b) {
                return a + ("," + b);
              }), "", param);
}

function keyOfUsageList(ls) {
  return joinStrings(List.map((function (item) {
                    return item[/* billing_token */0];
                  }), ls));
}

function usagesToColumns(param) {
  return List.map((function (ls) {
                return React.createElement("div", {
                            key: keyOfUsageList(ls),
                            className: "row"
                          }, $$Array.of_list(usagesToCards(ls)));
              }), param);
}

function readableMonth(month) {
  switch (month) {
    case 1 : 
        return "January";
    case 2 : 
        return "February";
    case 3 : 
        return "March";
    case 4 : 
        return "April";
    case 5 : 
        return "May";
    case 6 : 
        return "June";
    case 7 : 
        return "July";
    case 8 : 
        return "August";
    case 9 : 
        return "September";
    case 10 : 
        return "October";
    case 11 : 
        return "November";
    case 12 : 
        return "December";
    default:
      return "";
  }
}

function getMonthByOffset(offset) {
  var firstOf = function (param) {
    return new Date(new Date().setDate(1));
  };
  var targetMonth = firstOf(/* () */0).getMonth() + offset;
  return new Date(firstOf(/* () */0).setMonth(targetMonth));
}

function getMonth(date) {
  return (date.getMonth() | 0) + 1 | 0;
}

function getYear(date) {
  return date.getFullYear() | 0;
}

var getLastSixMonths_000 = getMonthByOffset(0);

var getLastSixMonths_001 = /* :: */[
  getMonthByOffset(-1),
  /* :: */[
    getMonthByOffset(-2),
    /* :: */[
      getMonthByOffset(-3),
      /* :: */[
        getMonthByOffset(-4),
        /* :: */[
          getMonthByOffset(-5),
          /* :: */[
            getMonthByOffset(-6),
            /* [] */0
          ]
        ]
      ]
    ]
  ]
];

var getLastSixMonths = /* :: */[
  getLastSixMonths_000,
  getLastSixMonths_001
];

var monthYearRange = List.map((function (date) {
        return /* tuple */[
                getMonth(date),
                date.getFullYear() | 0
              ];
      }), getLastSixMonths);

function mkMonthYearList(send, selectedMonthYear) {
  return List.map((function (param) {
                var year = param[1];
                var month = param[0];
                var classes = Caml_obj.caml_equal(selectedMonthYear, /* tuple */[
                      month,
                      year
                    ]) ? "knl-list-item selected" : "knl-list-item";
                return React.createElement("div", {
                            key: String(month) + String(year),
                            className: classes,
                            onClick: (function (param) {
                                return Curry._1(send, /* MonthYearSelected */Block.__(0, [
                                              month,
                                              year
                                            ]));
                              })
                          }, readableMonth(month) + (" " + String(year)));
              }), monthYearRange);
}

var stopNewActions = /* record */[/* contents */false];

function make(store, _children) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */(function (self) {
              stopNewActions[0] = false;
              return Curry._1(self[/* send */3], /* Fetch */Block.__(1, [/* Load */0]));
            }),
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */(function (self) {
              stopNewActions[0] = true;
              return /* () */0;
            }),
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function (self) {
              var match = self[/* state */1][/* usageList */1];
              var tmp;
              tmp = typeof match === "number" || match.tag !== 1 ? React.createElement("div", undefined) : $$Array.of_list(/* :: */[
                      ReasonReact.element("fleet-data-consumption", undefined, KNLCard.make(undefined, "MONTHLY DATA CONSUMPTION", /* array */[])),
                      /* :: */[
                        $$Array.of_list(List.rev(usagesToColumns(chunk(undefined, undefined, List.sort((function (a, b) {
                                                return Caml_primitive.caml_string_compare(a[/* name */5], b[/* name */5]);
                                              }), match[0]))))),
                        /* [] */0
                      ]
                    ]);
              return React.createElement("div", {
                          className: "row",
                          name: "Fleet"
                        }, self[/* state */1][/* usageList */1] === /* Loading */1 ? ReasonReact.element(undefined, undefined, Loading.make(/* array */[])) : React.createElement("div", undefined), React.createElement("div", {
                              className: "col-lg-2 col-md-2 col-sm-12"
                            }, ReasonReact.element(undefined, undefined, KNLCard.make(undefined, "MONTH", /* array */[React.createElement("div", {
                                            className: "knl-list"
                                          }, React.createElement("div", {
                                                className: "knl-list-header"
                                              }, "Choose month"), $$Array.of_list(mkMonthYearList(self[/* send */3], self[/* state */1][/* selectedMonthYear */0])))]))), React.createElement("div", {
                              className: "col-lg-10 col-md-10 col-sm-12"
                            }, tmp));
            }),
          /* initialState */(function (param) {
              var date = new Date();
              return /* record */[
                      /* selectedMonthYear : tuple */[
                        getMonth(new Date()),
                        date.getFullYear() | 0
                      ],
                      /* usageList : Idle */0
                    ];
            }),
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */(function (action, state) {
              if (action.tag) {
                var match = action[0];
                if (typeof match === "number") {
                  return /* UpdateWithSideEffects */Block.__(2, [
                            /* record */[
                              /* selectedMonthYear */state[/* selectedMonthYear */0],
                              /* usageList : Loading */1
                            ],
                            (function (self) {
                                UserData.getUsageForSubscriptions(state[/* selectedMonthYear */0][0], state[/* selectedMonthYear */0][1]).then((function (result) {
                                        return Promise.resolve(Curry._1(self[/* send */3], /* Fetch */Block.__(1, [result])));
                                      }));
                                return /* () */0;
                              })
                          ]);
                } else if (match.tag) {
                  var match$1 = match[0];
                  if (match$1) {
                    return /* Update */Block.__(0, [/* record */[
                                /* selectedMonthYear */state[/* selectedMonthYear */0],
                                /* usageList : Failed */Block.__(0, [match$1[0]])
                              ]]);
                  } else {
                    return /* UpdateWithSideEffects */Block.__(2, [
                              /* record */[
                                /* selectedMonthYear */state[/* selectedMonthYear */0],
                                /* usageList : Failed */Block.__(0, ["Unauthorized"])
                              ],
                              (function (_self) {
                                  return Curry._1(store[/* logout */2], /* () */0);
                                })
                            ]);
                  }
                } else {
                  return /* Update */Block.__(0, [/* record */[
                              /* selectedMonthYear */state[/* selectedMonthYear */0],
                              /* usageList : Done */Block.__(1, [match[0]])
                            ]]);
                }
              } else {
                return /* UpdateWithSideEffects */Block.__(2, [
                          /* record */[
                            /* selectedMonthYear : tuple */[
                              action[0],
                              action[1]
                            ],
                            /* usageList */state[/* usageList */1]
                          ],
                          (function (self) {
                              return Curry._1(self[/* send */3], /* Fetch */Block.__(1, [/* Load */0]));
                            })
                        ]);
              }
            }),
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

exports.str = str;
exports.component = component;
exports.usagesToCards = usagesToCards;
exports.chunk = chunk;
exports.joinStrings = joinStrings;
exports.keyOfUsageList = keyOfUsageList;
exports.usagesToColumns = usagesToColumns;
exports.readableMonth = readableMonth;
exports.getMonthByOffset = getMonthByOffset;
exports.getMonth = getMonth;
exports.getYear = getYear;
exports.getLastSixMonths = getLastSixMonths;
exports.monthYearRange = monthYearRange;
exports.mkMonthYearList = mkMonthYearList;
exports.stopNewActions = stopNewActions;
exports.make = make;
/* component Not a pure module */

// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Dialog = require("./Dialog");
var Loading = require("./Loading");
var $$Storage = require("../Util/Storage");
var Password = require("../Util/Password");
var UserData = require("../Util/UserData");
var ReasonReact = require("reason-react/src/ReasonReact.js");

function str(prim) {
  return prim;
}

var component = ReasonReact.reducerComponent("ChangePasswordForm");

function showHide(visible) {
  if (visible) {
    return /* Hide */4;
  } else {
    return /* Show */5;
  }
}

function make(store, _children) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */(function (updated) {
              return $$Storage.setBoolToStorage("ChangePasswordForm.visible", updated[/* newSelf */1][/* state */1][/* visible */0]);
            }),
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function (self) {
              var tmp;
              if (self[/* state */1][/* visible */0]) {
                var match = self[/* state */1][/* submitState */4];
                tmp = React.createElement("div", {
                      name: "change-password-form"
                    }, React.createElement("fieldset", undefined, React.createElement("label", undefined, "Current password"), React.createElement("input", {
                              name: "change-password-form-password",
                              type: "password",
                              value: self[/* state */1][/* currentPassword */1],
                              onChange: (function (e) {
                                  return Curry._1(self[/* send */3], /* CurrentPasswordUpdate */Block.__(1, [e.target.value]));
                                })
                            })), React.createElement("fieldset", undefined, React.createElement("label", undefined, "New password"), React.createElement("input", {
                              name: "change-password-form-new-pw-1",
                              type: "password",
                              value: self[/* state */1][/* newPassword1 */2],
                              onChange: (function (e) {
                                  return Curry._1(self[/* send */3], /* NewPassword1Update */Block.__(2, [e.target.value]));
                                })
                            })), React.createElement("fieldset", undefined, React.createElement("label", undefined, "Re-write the new password"), React.createElement("input", {
                              name: "change-password-form-new-pw-2",
                              type: "password",
                              value: self[/* state */1][/* newPassword2 */3],
                              onChange: (function (e) {
                                  return Curry._1(self[/* send */3], /* NewPassword2Update */Block.__(3, [e.target.value]));
                                })
                            })), React.createElement("div", {
                          className: "settings-bottom"
                        }, typeof match === "number" ? React.createElement("div", undefined) : React.createElement("div", {
                                className: "settings-error"
                              }, match[0]), React.createElement("button", {
                              className: "tertiary",
                              disabled: !Password.validNewPassword(self[/* state */1][/* newPassword1 */2], self[/* state */1][/* newPassword2 */3]),
                              name: "change-password-form-submit"
                            }, "SAVE")));
              } else {
                tmp = React.createElement("div", undefined);
              }
              return React.createElement("form", {
                          className: "settings-form",
                          onSubmit: (function (e) {
                              e.preventDefault();
                              return Curry._1(self[/* send */3], /* ShowDialog */0);
                            })
                        }, self[/* state */1][/* submitState */4] === /* Submitting */1 ? ReasonReact.element(undefined, undefined, Loading.make(/* array */[])) : React.createElement("div", undefined), self[/* state */1][/* showDialog */5] ? ReasonReact.element(undefined, undefined, Dialog.make(300, 200, (function (param) {
                                      return Curry._1(self[/* send */3], /* Submit */2);
                                    }), (function (param) {
                                      return Curry._1(self[/* send */3], /* HideDialog */1);
                                    }), /* array */[React.createElement("div", {
                                          className: "user-page-dialog"
                                        }, React.createElement("p", undefined, "You are about to change your password."), React.createElement("p", undefined, React.createElement("strong", undefined, "Are you sure?")))])) : React.createElement("div", undefined), React.createElement("legend", {
                              name: "show-change-password-form",
                              onClick: (function (_e) {
                                  return Curry._1(self[/* send */3], self[/* state */1][/* visible */0] ? /* Hide */4 : /* Show */5);
                                })
                            }, "CHANGE PASSWORD", self[/* state */1][/* visible */0] ? React.createElement("i", {
                                    className: "fa fa-chevron-up"
                                  }) : React.createElement("i", {
                                    className: "fa fa-chevron-down"
                                  })), tmp);
            }),
          /* initialState */(function (param) {
              return /* record */[
                      /* visible */false,
                      /* currentPassword */"",
                      /* newPassword1 */"",
                      /* newPassword2 */"",
                      /* submitState : Idle */0,
                      /* showDialog */false
                    ];
            }),
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */(function (action, state) {
              if (typeof action === "number") {
                switch (action) {
                  case 0 : 
                      return /* Update */Block.__(0, [/* record */[
                                  /* visible */state[/* visible */0],
                                  /* currentPassword */state[/* currentPassword */1],
                                  /* newPassword1 */state[/* newPassword1 */2],
                                  /* newPassword2 */state[/* newPassword2 */3],
                                  /* submitState */state[/* submitState */4],
                                  /* showDialog */true
                                ]]);
                  case 1 : 
                      return /* Update */Block.__(0, [/* record */[
                                  /* visible */state[/* visible */0],
                                  /* currentPassword */state[/* currentPassword */1],
                                  /* newPassword1 */state[/* newPassword1 */2],
                                  /* newPassword2 */state[/* newPassword2 */3],
                                  /* submitState */state[/* submitState */4],
                                  /* showDialog */false
                                ]]);
                  case 2 : 
                      return /* UpdateWithSideEffects */Block.__(2, [
                                /* record */[
                                  /* visible */state[/* visible */0],
                                  /* currentPassword */state[/* currentPassword */1],
                                  /* newPassword1 */state[/* newPassword1 */2],
                                  /* newPassword2 */state[/* newPassword2 */3],
                                  /* submitState : Submitting */1,
                                  /* showDialog */false
                                ],
                                (function (self) {
                                    UserData.changePassword(store[/* state */0][/* uuid */3], self[/* state */1][/* newPassword1 */2], self[/* state */1][/* currentPassword */1]).then((function (result) {
                                            if (result.tag) {
                                              return Promise.resolve(Curry._1(self[/* send */3], /* SubmitFailed */Block.__(0, [result[0]])));
                                            } else {
                                              return Promise.resolve(Curry._1(self[/* send */3], /* Submitted */3));
                                            }
                                          }));
                                    return /* () */0;
                                  })
                              ]);
                  case 3 : 
                      return /* UpdateWithSideEffects */Block.__(2, [
                                /* record */[
                                  /* visible */state[/* visible */0],
                                  /* currentPassword */state[/* currentPassword */1],
                                  /* newPassword1 */state[/* newPassword1 */2],
                                  /* newPassword2 */state[/* newPassword2 */3],
                                  /* submitState : Done */2,
                                  /* showDialog */state[/* showDialog */5]
                                ],
                                (function (self) {
                                    Curry._1(self[/* send */3], /* CurrentPasswordUpdate */Block.__(1, [""]));
                                    Curry._1(self[/* send */3], /* NewPassword1Update */Block.__(2, [""]));
                                    Curry._1(self[/* send */3], /* NewPassword2Update */Block.__(3, [""]));
                                    return Curry._1(store[/* dispatch */1], /* LoggedOut */0);
                                  })
                              ]);
                  case 4 : 
                      return /* Update */Block.__(0, [/* record */[
                                  /* visible */false,
                                  /* currentPassword */state[/* currentPassword */1],
                                  /* newPassword1 */state[/* newPassword1 */2],
                                  /* newPassword2 */state[/* newPassword2 */3],
                                  /* submitState */state[/* submitState */4],
                                  /* showDialog */state[/* showDialog */5]
                                ]]);
                  case 5 : 
                      return /* Update */Block.__(0, [/* record */[
                                  /* visible */true,
                                  /* currentPassword */state[/* currentPassword */1],
                                  /* newPassword1 */state[/* newPassword1 */2],
                                  /* newPassword2 */state[/* newPassword2 */3],
                                  /* submitState */state[/* submitState */4],
                                  /* showDialog */state[/* showDialog */5]
                                ]]);
                  
                }
              } else {
                switch (action.tag | 0) {
                  case 0 : 
                      var match = action[0];
                      if (match) {
                        return /* Update */Block.__(0, [/* record */[
                                    /* visible */state[/* visible */0],
                                    /* currentPassword */state[/* currentPassword */1],
                                    /* newPassword1 */state[/* newPassword1 */2],
                                    /* newPassword2 */state[/* newPassword2 */3],
                                    /* submitState : Failed */[match[0]],
                                    /* showDialog */state[/* showDialog */5]
                                  ]]);
                      } else {
                        return /* Update */Block.__(0, [/* record */[
                                    /* visible */state[/* visible */0],
                                    /* currentPassword */state[/* currentPassword */1],
                                    /* newPassword1 */state[/* newPassword1 */2],
                                    /* newPassword2 */state[/* newPassword2 */3],
                                    /* submitState : Failed */["Check that the old password is correct and that the new password matches the requirements"],
                                    /* showDialog */state[/* showDialog */5]
                                  ]]);
                      }
                  case 1 : 
                      return /* Update */Block.__(0, [/* record */[
                                  /* visible */state[/* visible */0],
                                  /* currentPassword */action[0],
                                  /* newPassword1 */state[/* newPassword1 */2],
                                  /* newPassword2 */state[/* newPassword2 */3],
                                  /* submitState */state[/* submitState */4],
                                  /* showDialog */state[/* showDialog */5]
                                ]]);
                  case 2 : 
                      return /* Update */Block.__(0, [/* record */[
                                  /* visible */state[/* visible */0],
                                  /* currentPassword */state[/* currentPassword */1],
                                  /* newPassword1 */action[0],
                                  /* newPassword2 */state[/* newPassword2 */3],
                                  /* submitState */state[/* submitState */4],
                                  /* showDialog */state[/* showDialog */5]
                                ]]);
                  case 3 : 
                      return /* Update */Block.__(0, [/* record */[
                                  /* visible */state[/* visible */0],
                                  /* currentPassword */state[/* currentPassword */1],
                                  /* newPassword1 */state[/* newPassword1 */2],
                                  /* newPassword2 */action[0],
                                  /* submitState */state[/* submitState */4],
                                  /* showDialog */state[/* showDialog */5]
                                ]]);
                  
                }
              }
            }),
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

exports.str = str;
exports.component = component;
exports.showHide = showHide;
exports.make = make;
/* component Not a pure module */

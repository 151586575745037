const decimalToDegree = (value, max, monospace) => {
    var sign = value < 0 ? -1 : 1;
    var abs = Math.abs(Math.round(value * 1000000));
    if (abs > (max * 1000000)) {
        return NaN;
    }
    var dec = abs % 1000000 / 1000000;
    var deg = Math.floor(abs / 1000000) * sign;
    var min = Math.floor(dec * 60);
    var sec = (dec - min / 60) * 3600;
    var result = "";
    var CHAR_DEG = "\u00B0";
    var CHAR_MIN = "\u0027";
    var CHAR_SEP = "\u0020";
    
    if (monospace) {  
        var val = 4 - String(deg).length;
        deg = new Array(val+1).join("&nbsp;") + String(deg);
        val = 2 - String(min).length;
        min = new Array(val+1).join("&nbsp;") + String(min);
    }

    result += deg;
    result += CHAR_DEG;
    result += CHAR_SEP;
    result += min + '.';
    result += String((sec / 60).toFixed(2)).split('.')[1];
    result += CHAR_MIN;
    result += CHAR_SEP;
    return result;
};

const lonToDegree = (value) => {
    value = decimalToDegree(value, 180);
    if (value) {
        if (value.startsWith('-')) {
            value += 'W';
            value = value.replace('-', '');
        } else {
            value += 'E'
        }
        return value;
    }
};

const latToDegree = function(value) {
    value = decimalToDegree(value, 90);
    if (value) {
        if (value.startsWith('-')) {
            value += 'S';
            value = value.replace('-', '');
        } else {
            value += 'N';
        }
        return value;
    }
};

export { lonToDegree, latToDegree };